import React, { useState } from "react";
// import { useLogin } from "../hooks/useLogin";
import loginImg from '../assets/images/login.png'
import logoGrad from '../assets/images/logo2.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
const ForgetPassword = () => {
    const [Email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [alert, setAlert] = useState("")


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        formData.append('email', Email);
        await axios.post(`/users/forget-password`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json.message)
                if(json.message === 'Email has been sent.'){
                    setMessage("Email has been sent")
                    setAlert("success")
                }
                else{
                    setMessage("Email is not found.")
                    setAlert("error")
                }
            })
            setIsLoading(false)
    };

    return (
        <div className="loginHolder">
            <div className="loginContainer">
                <div className="login-img" style={{ 'background': `url(${loginImg})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center', 'backgroundRepeat': 'no-repeat' }} />
                <form className="loginForm" onSubmit={handleSubmit}>
                    <div className="login-part1"> <img src={logoGrad} alt="Cardtap Logo"  /> </div>

                    <div className="login-part2">
                        <h3 style={{ 'fontFamily': 'poppins' }}>Enter Your Email</h3>
                        {message && <Alert severity={alert}>{message}</Alert>}
                        <div className="login-input">
                            <label>Email</label>
                            <div className="password-input">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={Email}
                            />
                            </div>
                        </div>

                        <button type="submit" >{!isLoading&&'Send Link'} {isLoading&&<CircularProgress />}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgetPassword;
