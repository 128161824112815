import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import { Outlet, useLocation } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import MobileNavbar from '../components/MobileNavbar';
import NotFound from '../pages/NotFound';
export default function RootLayout() {
    return (
        <div className="background">
            <div className="container">
                <Navbar />
                <MobileNavbar />
                <NotFound/>
            </div>
            <Footer />
        </div>
    )
}