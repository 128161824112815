import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

const AboutPage = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts (new route is loaded)
        window.scrollTo(0, 0);
      }, []);
    const questions=[
        {question:"What is Cardtap, and what do you offer?",answer:"Cardtap is a technology company specializing in NFC (Near Field Communication) products. We offer a wide range of NFC solutions, including NFC business cards, tags, and more. These products enable efficient data exchange and enhance connectivity in various industries."},
        {question:"How do NFC business cards work?",answer:"NFC business cards are embedded with a tiny chip that communicates wirelessly with NFC-enabled devices, such as smartphones. By tapping an NFC card to a compatible device, users can instantly share contact information, websites, social media links and many more, making networking quick and convenient."},
        {question:"What makes Cardtap's NFC products unique?",answer:"Cardtap stands out for its commitment to quality, innovation, and elegant design. Our NFC products combine cutting-edge technology with a sophisticated appearance, making a lasting impression while simplifying interactions."},
        {question:"Are NFC business cards eco-friendly?",answer:"Yes, NFC business cards are eco-friendly. They reduce the need for printed materials and contribute to a more sustainable environment by promoting paperless interactions."},
        {question:"Can I customize NFC business cards to match my brand?",answer:'Absolutely! Cardtap offers extensive <a href="/products/651b3dfa2b4af7127cb8ef4c" class="footer-link">customization options</a>. You can tailor the design, color, and content of your NFC business cards to align with your brand identity and message.'},
        {question:"Can i fully control my profile?",answer:'As a Cardtap member, you have full control of your profile. <a href="/login" class="footer-link">Sign in</a> with your accout at any time to update your information without requiring our assistance.'},
        {question:"Are NFC cards compatible with all smartphones?",answer:"Most modern smartphones are NFC-enabled. However, it's essential to check your device's specifications to ensure NFC compatibility. NFC technology is widely supported on Android and increasingly on newer iPhone models."},
        {question:"How can I order NFC products from Cardtap?",answer:'Ordering NFC products from Cardtap is easy. Simply visit our <a href="/Products" class="footer-link">products page</a>, explore our product range, and follow the straightforward ordering process. If you have specific customization requests, our team is here to assist you.'},
        {question:"Are NFC cards secure for data sharing?",answer:"Yes, NFC cards are secure for data sharing. They use encrypted communication, making it difficult for unauthorized parties to intercept or manipulate the data being exchanged."},
        {question:"How can I get in touch with Cardtap for inquiries or support?",  answer: 'You can reach out to us from the <a href="#footer-newsletter" class="footer-link">website\'s footer</a>. We will respond to your inquiries as soon as possible and assist you with any support or customization needs.'},
        {question:"Can I use NFC cards for marketing and promotional purposes?",answer:"(Coming Soon) Absolutely! NFC cards are great for interactive marketing campaigns. You can program them to redirect users to your website, social media, or promotional content with a simple tap."},
        {question:"Do you offer bulk or wholesale pricing for NFC cards and tags?",answer:"Yes, we offer competitive pricing for bulk orders of NFC cards and tags. Contact our sales team for customized pricing based on your specific requirements."},
        {question:"Can I track the usage of NFC cards and gather analytics data?",answer:"Yes, it's possible to track NFC card usage and gather analytics data to gain insights into user interactions. You will find the “Analytics” button on your profile (If you are a cardtap member). This data can help you refine your strategies."},
        {question:"How long does an NFC card's lifespan typically last?",answer:"NFC cards are designed for durability and can last several years with normal use. The exact lifespan depends on factors like usage and environmental conditions."},
        {question:"Are NFC cards and tags compatible with both Android and iOS devices?",answer:"Yes, they are compatible with most Android and IOS devices. You can check the compatible devices from here."},
        {question:"What should I do if my mobile phone doesn't support NFC technology?",answer:"Do not worry, every card has a QR code that redirects to the same URL as the NFC. So, you can scan the QR code with your mobile camera to access the profile."},
        {question:"What are the Card dimensions?",answer:"The card dimensions are: 8.5x5.5 cm. Note: The card is rectangular in shape."},
        {question:"What are the Tag dimensions?",answer:"The tag dimensions are: 3x3 cm. Note: The tag is circular in shape."},
    ]
    return (
        <div>
            <Helmet>
            <title>About Cardtap - Building a Future with NFC Technology</title>
            <meta name="description" content="Cardtap's journey in NFC technology - innovation, commitment, and a vision for excellence. Check all the frequently asked questions (FAQ)." />
            </Helmet>
        <div className="aboutContainer">
            <div className="sectionTitle">
                <h1>Mission</h1>
            </div> 
            <div className="sectionDescription">
                <p>At cardtap, Our mission is to produce NFC business cards, tags, and related products that enhance connectivity, efficiency, and engagement, synchronizing with the world-wide digital transformation. We aim to provide user-friendly solutions, and sustainable practices drives us to be a trusted partner for our customers.</p>
            </div>
            <div className="sectionTitle">
                <h1>Vision</h1>
            </div>
            <div className="sectionDescription">
                <p>At cardtap, Our vission is to be a global leader in NFC technology, setting new standards for elegance and functionality. We envision a world where our NFC products revolutionize how people connect, share information, and conduct business. As we pursue this vision, We remain dedicated to reach the best customer satisfaction rate and enrich the lives of our customers and communities worldwide.</p>
            </div>
            <div className="sectionTitle">
                <h1>FAQ</h1>
            </div>
            <div className="sectionDescription">
            <ol className="questionsList">
                {questions.map(question=>( 
                <li key={question.question}>
                    <details className='faq'>
                        <summary className='question'><span>{question.question}</span>		
                            <div className="open-answer">
			                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
		                    </div>
                        </summary>
                        <div className='answer'><p ><div className='answer' dangerouslySetInnerHTML={{ __html: question.answer }}></div></p></div>
                    </details>
                </li>
                ))}
            </ol>
            </div>
        </div>
        </div>
    );
}

export default AboutPage