import { createContext, useReducer } from "react";
import { useEffect } from "react";
export const CartContext = createContext();

export const CartReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CART':
            return {
                cart: action.payload
            }
        case 'ADD_ITEM':
            return {
                cart: [action.payload, ...state.cart]
            }
        case 'UPDATE_ITEM':
            return {
                cart: [...action.payload]
            }
        case 'DELETE_ITEM':
            return {
                cart: [...(state.cart.filter(w => w._id !== action.payload._id))]
            }
        case 'EMPTY_CART':
            return {
                cart: []
            }
        default:
            return state
    }
}

export const CartContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CartReducer, {
        cart: []
    })

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart'))
        if (cart) {
            dispatch({ type: 'SET_CART', payload: cart })
        }
    }, [])

    return (
        <CartContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CartContext.Provider>
    )
}