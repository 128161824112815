import { createContext, useReducer } from "react";
import { useEffect } from "react";
import useLogout from "../hooks/useLogout";
export const ProfileContext = createContext();

export const ProfileReducer = (state ,action) =>{
    switch(action.type){
        case 'SET_PROFILES':
            return{
                profile : action.payload
            }
        case 'UPDATE_PROFILES':
            return{
                profile: action.payload 
            }
        default:
            return state
    }
}

export const ProfileContextProvider = ({children}) =>{
    const { logout } = useLogout();
    const [state,dispatch] = useReducer(ProfileReducer,{
        profile:null
    })
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        const getUserProfiles = async () => {
            try{
                const response = await fetch(`/users/getuserData/${user.email}`,
                {
                    headers: {'Authorization': `Bearer ${user.token}`}
                }
                )
                const json = await response.json()
                if(!response.ok){
                    console.log(json.error.message);
                    if(json.error.message ==='jwt expired'){
                        logout()
                    }
                }
                else{
                    console.log(json)
                    dispatch({type:'SET_PROFILES',payload:json});
                }
            }
            catch(err){
                console.log("No Internet Connection")
                
            }
        }
        if (user) {
            getUserProfiles();
        }
      }, [])

    return(
        <ProfileContext.Provider value={{...state,dispatch}}>
            {children}
        </ProfileContext.Provider>
    )
}