import { createContext, useReducer } from "react";
import { useEffect } from "react";
export const CompanyEmployeesContext = createContext();

export const CompanyEmployeesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_EMPLOYEES':
            return {
                employees: action.payload
            }
        default:
            return state
    }
}

export const CompanyEmployeesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CompanyEmployeesReducer, {
        employees: []
    })
    return (
        <CompanyEmployeesContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CompanyEmployeesContext.Provider>
    )
}