import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { useUserContext } from "../../hooks/useUserContext";
import Frame from '../../assets/images/Frame 3.png'
import Frame2 from '../../assets/images/Group 9.png'
import Frame3 from '../../assets/images/Group 10.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import { useOutletContext } from "react-router-dom";
import { useProfileContext } from "../../hooks/useProfileContext"
import React from "react";
import { Typography, Card, CardContent, Box, Grid, colors } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { blue, red } from "@mui/material/colors";
Chart.register(CategoryScale);
const Analytics = () => {
    console.log("corp")
    const employeesProfiles = useOutletContext()
    const { user } = useUserContext();
    const { profile } = useProfileContext();
    //Total Taps
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    let monthData = []
    let monthMap = new Map()
    monthMap.set("Jan", 0)
    monthMap.set("Feb", 1)
    monthMap.set("Mar", 2)
    monthMap.set("Apr", 3)
    monthMap.set("May", 4)
    monthMap.set("June", 5)
    monthMap.set("July", 6)
    monthMap.set("Aug", 7)
    monthMap.set("Sep", 8)
    monthMap.set("Oct", 9)
    monthMap.set("Nov", 10)
    monthMap.set("Dec", 11)
    let tapsSum = 0;
    if (profile) {
        for (const key in profile.numberOfTapsDate) {
            let value = profile.numberOfTapsDate[key];
            tapsSum += value;
            let monthArr = key.split('-')
            let realMonth = parseInt(monthArr[0])
            console.log(realMonth)
            monthData.push(months[realMonth - 1])
        }

        while (monthData.length < 12) {
            let lastMonth = monthData[monthData.length - 1]
            let pointer = (monthMap.get(lastMonth) + 1) % 12
            monthData.push(months[pointer])
        }
    }
    let tapEntries,
        tapLastkey,
        tapLastvalue,
        tapEntries2,
        tapLastkey2,
        tapLastvalue2,
        tapChange=0,
        contactEntries,
        contactlastkey,
        contactlastvalue,
        contactEntries2,
        contactlastkey2,
        contactlastvalue2,
        contactChange=0,
        profileEntries,
        profilelastkey,
        profilelastvalue,
        profileEntries2,
        profilelastkey2,
        profilelastvalue2,
        profileChange=0,
        socialSum,
        socialSum2,
        socialChange=0,
        lastTapCounts,
        combinedData = [],
        employeesCounter = [],
        topColors = ["#00223E", "#AF7160", "#003E3E", "#AF8B60", "#3E7000"]
    if (profile)
    //Taps
    {
        tapEntries = Object.entries(profile.numberOfTapsDate);
        tapLastkey = tapEntries[tapEntries.length - 1];
        tapLastvalue = tapLastkey[1];
        //Taps Percentage 
        tapEntries2 = Object.entries(profile.numberOfTapsDate);
        if (tapEntries2[tapEntries2.length - 2]) {
            tapLastkey2 = tapEntries2[tapEntries2.length - 2];
            tapLastvalue2 = tapLastkey2[1];
            tapChange = ((tapLastvalue / tapLastvalue2) * 100) - 100
            tapChange = tapChange.toFixed(2);
        }
        //Contact Downloads
        contactEntries = Object.entries(profile.noOfContactDownloads);
        contactlastkey = contactEntries[contactEntries.length - 1];
        contactlastvalue = contactlastkey[1];
        //Download Percentage 
        contactEntries2 = Object.entries(profile.noOfContactDownloads);
        if (contactEntries2[tapEntries2.length - 2]) {
            contactlastkey2 = contactEntries2[tapEntries2.length - 2];
            contactlastvalue2 = contactlastkey2[1];
            contactChange = ((contactlastvalue / contactlastvalue2) * 100) - 100
            contactChange = contactChange.toFixed(2);
        }
        //Contacts Saved
        profileEntries = Object.entries(profile.profilesSaved);
        profilelastkey = profileEntries[profileEntries.length - 1];
        profilelastvalue = profilelastkey[1];
        //Saved Percentage
        profileEntries2 = Object.entries(profile.profilesSaved);
        if (profileEntries2[profileEntries2.length - 2]) {
            profilelastkey2 = profileEntries2[profileEntries2.length - 2];
            profilelastvalue2 = profilelastkey2[1];
            profileChange = ((profilelastvalue / profilelastvalue2) * 100) - 100
            profileChange = profileChange.toFixed(2);
        }
        //Social Media
        socialSum = 0

        for (const i in profile.profiles[0].socialMedia) {
            const SocialEntries = Object.entries(profile.profiles[0].socialMedia[i].NoOfTaps);
            const Sociallastkey = SocialEntries[SocialEntries.length - 1];
            const Sociallastvalue = Sociallastkey[1];
            socialSum += Sociallastvalue
        }
        //Social Media Percentage
        socialSum2 = 0
        for (const i in profile.profiles[0].socialMedia) {
            const SocialEntries = Object.entries(profile.profiles[0].socialMedia[i].NoOfTaps);
            const Sociallastkey = SocialEntries[SocialEntries.length - 1];
            const Sociallastvalue = Sociallastkey[1];
            socialSum2 += Sociallastvalue
        }
        socialChange = ((socialSum / socialSum2) * 100) - 100
        socialChange = socialChange.toFixed(2);
        //social media pie chart
        const getLastNoOfTaps = (app) => {
            const months = Object.keys(app.NoOfTaps);
            const lastMonth = months[months.length - 1];
            return app.NoOfTaps[lastMonth];
        };
        if (profile) {
            const usedColors = new Set(); // Keep track of used colors
            combinedData = profile.profiles[0].socialMedia.map(app => {
                return {
                    label: app.PlatformName,
                    data: [getLastNoOfTaps(app)],
                    backgroundColor: "#000000",
                };
            })
                .sort((a, b) => b.data[0] - a.data[0]) // Sort in descending order based on data
                .slice(0, 5); // Get the top five entries
            assignColor(combinedData)
        }

        //Employee chart
        const getEmployessTaps = (employee) => {
            const months = Object.keys(employee.numberOfTapsDate);
            const lastMonth = months[months.length - 1];
            return employee.numberOfTapsDate[lastMonth];
        };
        if (employeesProfiles) {
            employeesCounter = employeesProfiles.map(employee => {
                return {
                    label: employee.name,
                    data: [getEmployessTaps(employee)],
                    backgroundColor: "#000000",
                };
            })
                .sort((a, b) => b.data[0] - a.data[0]) // Sort in descending order based on data
                .slice(0, 5); // Get the top five entries
            assignColor(employeesCounter)
        }

        function assignColor(obj) {
            for (const i in obj) {
                obj[i].backgroundColor = topColors[i]
            }
        }
    }





    const handleChartValues = async () => {
        let result
        try {
            result = await fetch( '/users/removeProfile', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            })
        }
        catch (err) {
            console.log(err)
        }
    }
    let date = []
    if (profile)
        for (const i in profile.numberOfTapsDate) {
            date.push(profile.numberOfTapsDate[i])
        }
    const data = {
        labels: monthData,
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
            {
                label: 'Number of taps',
                data: date,
                // you can set indiviual colors for each bar
                backgroundColor: [
                    'rgba(164, 73, 106, 1)'
                ],
                borderWidth: 3,
                borderColor: "#AE796A",
                hoverBackgroundColor: 'black',
                showLine: true,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 10,
                pointHoverBackgroundColor: "#ffffff",
                pointHoverBorderColor: "red",
                pointHoverBorderWidth: 5,
            }
        ]
    }
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }
    return (
        <div className="corporate-analytics-container">
            <div className="corporate-upperPart">
                <div className="corporate-upperPart-holder">
                    <div className="chart-container">
                        <Line
                            data={data}
                            options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: "Total Taps"
                                    },
                                    legend: {
                                        display: false
                                    },
                                    maintainAspectRatio: false,
                                }
                            }}
                        />
                        <div className="stat-totaltaps"> <h1>Total taps</h1> <h1> {tapsSum}</h1> </div>
                    </div>
                    <div className="corporate-stats-desktop">
                        <div className="stat-box">
                            <div className="stat-box-left">
                                <img src={Frame} />
                                <span className="stat-title">Card taps</span>
                                <span className="stat-tap">{tapLastvalue}</span>
                            </div>
                            <div className="stat-box-right">
                                <div className="stat-box-right-upper">
                                    <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(tapChange)}%</span>
                                    <img src={tapChange < 0 ? Frame2 : Frame3} />
                                </div>
                                <span className="stat-month" >this month</span>
                            </div>
                        </div>
                        <div className="stat-box">
                            <div className="stat-box-left">
                                <img src={Frame} />
                                <span className="stat-title">Application taps</span>
                                <span className="stat-tap">{socialSum}</span>
                            </div>
                            <div className="stat-box-right">
                                <div className="stat-box-right-upper">
                                    <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(socialChange)}%</span>
                                    <img src={socialChange < 0 ? Frame2 : Frame3} />
                                </div>
                                <span className="stat-month">this month</span>
                            </div>
                        </div>
                        <div className="stat-box">
                            <div className="stat-box-left">
                                <img src={Frame} />
                                <span className="stat-title">Contact saves</span>
                                <span className="stat-tap">{contactlastvalue}</span>
                            </div>
                            <div className="stat-box-right">
                                <div className="stat-box-right-upper">
                                    <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(contactChange)}%</span>
                                    <img src={contactChange < 0 ? Frame2 : Frame3} />
                                </div>
                                <span className="stat-month">this month</span>
                            </div>
                        </div>
                        <div className="stat-box">
                            <div className="stat-box-left">
                                <img src={Frame} />
                                <span className="stat-title">New contacts</span>
                                <span className="stat-tap">{profilelastvalue}</span>
                            </div>
                            <div className="stat-box-right">
                                <div className="stat-box-right-upper">
                                    <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(profileChange)}%</span>
                                    <img src={profileChange < 0 ? Frame2 : Frame3} />
                                </div>
                                <span className="stat-month">this month</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ 'width': '100%' }}>
                    <div style={{ 'width': '100%' }} className="corporate-stats-mobile">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1.5}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <div className="stat-box">
                                    <div className="stat-box-left">
                                        <img src={Frame} />
                                        <span className="stat-title">Card taps</span>
                                        <span className="stat-tap">{tapLastvalue}</span>
                                    </div>
                                    <div className="stat-box-right">
                                        <div className="stat-box-right-upper">
                                            <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(tapChange)}%</span>
                                            <img src={tapChange < 0 ? Frame2 : Frame3} />
                                        </div>
                                        <span className="stat-month">this month</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="stat-box">
                                    <div className="stat-box-left">
                                        <img src={Frame} />
                                        <span className="stat-title">Application taps</span>
                                        <span className="stat-tap">{socialSum}</span>
                                    </div>
                                    <div className="stat-box-right">
                                        <div className="stat-box-right-upper">
                                            <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(socialChange)}%</span>
                                            <img src={socialChange < 0 ? Frame2 : Frame3} />
                                        </div>
                                        <span className="stat-month">this month</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="stat-box">
                                    <div className="stat-box-left">
                                        <img src={Frame} />
                                        <span className="stat-title">Contact saves</span>
                                        <span className="stat-tap">{contactlastvalue}</span>
                                    </div>
                                    <div className="stat-box-right">
                                        <div className="stat-box-right-upper">
                                            <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(contactChange)}%</span>
                                            <img src={contactChange < 0 ? Frame2 : Frame3} />
                                        </div>
                                        <span className="stat-month">this month</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="stat-box">
                                    <div className="stat-box-left">
                                        <img src={Frame} />
                                        <span className="stat-title">New contacts</span>
                                        <span className="stat-tap">{profilelastvalue}</span>
                                    </div>
                                    <div className="stat-box-right">
                                        <div className="stat-box-right-upper">
                                            <span style={{ 'marginRight': '4px', 'fontSize': '15px' }}>{Math.abs(profileChange)}%</span>
                                            <img src={profileChange < 0 ? Frame2 : Frame3} />
                                        </div>
                                        <span className="stat-month">this month</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="corporate-lowerPart">

                <Grid className="ChartsContainer">
                    <div className="ring-stat">
                        <p style={{ 'fontFamily': 'poppins', 'fontSize': '15px', 'fontWeight': '500', 'alignItems': 'center' }}>Most Applications Taps</p>
                        <CardContent className="PieChart">
                            <Box sx={{ height: 150, mt: 2, width: '100%' }}>
                                <Pie
                                    data={{
                                        labels: combinedData.map(app => app.label),
                                        datasets: [
                                            {
                                                data: combinedData.map(app => app.data[0]),
                                                backgroundColor: combinedData.map(app => app.backgroundColor),
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: "left",
                                                fontFamily: 'poppins',
                                                fontWeight: '500',
                                            },
                                        },
                                        maintainAspectRatio: false, // Set to false to have a constant size
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </div>
                </Grid>

                <div className="anal-splitter" />

                <Grid className="ChartsContainer">
                    <div className="ring-stat">
                        <p style={{ 'fontFamily': 'poppins', 'fontSize': '15px', 'fontWeight': '500', 'alignItems': 'center' }}>Most Employees Taps</p>
                        <CardContent className="PieChart">
                            <Box sx={{ height: 150, mt: 2, width: '100%' }}>
                                <Pie
                                    data={{
                                        labels: employeesCounter.map(employee => employee.label),
                                        datasets: [
                                            {
                                                data: employeesCounter.map(employee => employee.data[0]),
                                                backgroundColor: employeesCounter.map(employee => employee.backgroundColor),
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: "left",
                                                fontFamily: 'poppins',
                                                fontWeight: '500',
                                            }
                                        },
                                        maintainAspectRatio: false, // Set to false to have a constant size
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </div>
                </Grid>
            </div>
        </div>
    );
}
export default Analytics