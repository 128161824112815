import { useEffect, useState } from "react";
import { useProfileContext } from "../hooks/useProfileContext";
import MobileMocket from "./MobileMocket";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../hooks/useUserContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useOutletContext } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
const ContactInfo = () => {
    const navigate = useNavigate();
    const { profile, dispatch } = useProfileContext();
    console.log(profile)
    const [handleTutorialClick] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useUserContext()
    const [firstName, setFirstName] = useState(profile.profiles[profile.activeProfile].firstName);
    const [lastName, setlastName] = useState(profile.profiles[profile.activeProfile].lastName);
    const [title, setTitle] = useState(profile.profiles[profile.activeProfile].title);
    const [organization, setOrganization] = useState(profile.profiles[profile.activeProfile].organization);
    const [number1, setNumber1] = useState(profile.profiles[profile.activeProfile].workPhone);
    const [number2, setNumber2] = useState(profile.profiles[profile.activeProfile].homePhone);
    const [email1, setEmail1] = useState(profile.profiles[profile.activeProfile].email2);
    const [email2, setEmail2] = useState(profile.profiles[profile.activeProfile].email3);
    const [address1, setAddress1] = useState(profile.profiles[profile.activeProfile].address1);
    const [address2, setAddress2] = useState(profile.profiles[profile.activeProfile].address2);

    useEffect(() => {
        if (handleTutorialClick[0]) {
            console.log('Start Tutorial button clicked in Outlet component');
            const driverObj1 = driver({
                showProgress: true,  // Because everyone loves progress bars!
                steps: [
                    {
                        element: '.dashboardContent',
                        popover: {
                            title: 'Personal Info.',
                            description: `Here is your personal information page, which holds all of your contact information which then can be downloaded to your customer when he clicks on "SAVE CONTACT" button inside your profile `
                        }
                    },
                    {
                        element: '.profileContainer',
                        popover: {
                            title: 'Your Profile',
                            description: "Here are your profile overview."
                        }
                    },
                    {
                        element: '.contactInput',
                        popover: {
                            title: 'Profile Contact Details',
                            description: `Here you can enter the data you need to be downloaded as a contact in your customer's mobile when he clicks on "SAVE CONTACT" button in your profile .`
                        }
                    }
                ]
            });
            driverObj1.drive();

            // Call your function or perform any other actions here
        }
     }, [profile,handleTutorialClick])

    const handleSave = async () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('title', title);
        formData.append('organization', organization);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('homePhone', number1);
        formData.append('workPhone', number2);
        formData.append('address1', address1);
        formData.append('address2', address2);
        formData.append('email2', email1);
        formData.append('email3', email2);
        formData.append('image1', profile.profiles[profile.activeProfile].image1);
        formData.append('public_id_con', profile.profiles[profile.activeProfile].public_id_con);
        try{
            await axios.post(`/users/contactUpdate`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    dispatch({ type: "UPDATE_PROFILES", payload: json })
                    setIsLoading(false)
                })
        }
        catch(err){
            console.log("No internet connection")
        }
    }

    return (
        <div className="socialMediaHolder">
            <div className="mobile-contactInfoHeader">
                <h2 style={{fontFamily:'poppins',fontSize:'20px'}}>
                    Personal Info
                </h2>
            </div>
            <div className="profileView-leftPart">
                <MobileMocket profile={profile.profiles[profile.activeProfile]} />
            </div>
            {isLoading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <div className="profileView-rightPart rightPart-contacts">
                <div className="contactInput">
                    <p style={{'width':'80%','opacity':'0.7','fontSize':'15px','borderBottom':'1px solid','paddingBottom':'10px'}}> Any data entered here, will be downloaded as a contact saved in the client's contacts, when you press on <strong>Save Contact</strong> button </p>
                    <div className="upperInputs">
                        <div className="inputHolderUpper">
                            <label>First Name</label>
                            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderUpper">
                            <label>Last Name</label>
                            <input value={lastName} onChange={(e) => setlastName(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderUpper">
                            <label>Title</label>
                            <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderUpper">
                            <label>Organization</label>
                            <input value={organization} onChange={(e) => setOrganization(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderUpper">
                            <label>Work Number 1</label>
                            <input value={number1} onChange={(e) => setNumber1(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderUpper">
                            <label>Work Number 2</label>
                            <input value={number2} onChange={(e) => setNumber2(e.target.value)} type="text" />
                        </div>
                    </div>
                    <div className="lowerInputs">
                        <div className="inputHolderLower">
                            <label>Work Email 1</label>
                            <input value={email1} onChange={(e) => setEmail1(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderLower">
                            <label>Work Email 2</label>
                            <input value={email2} onChange={(e) => setEmail2(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderLower">
                            <label>Address 1</label>
                            <input value={address1} onChange={(e) => setAddress1(e.target.value)} type="text" />
                        </div>
                        <div className="inputHolderLower">
                            <label>Address 2</label>
                            <input value={address2} onChange={(e) => setAddress2(e.target.value)} type="text" />
                        </div>
                    </div>
                </div>
                <div style={{'marginTop':'10px'}} className="buttonsHolder saveButtonContactInfo">
                    <button onClick={handleSave}> SAVE</button>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo