import { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import DashboardNavbar from "../components/DashboardNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/font-logo.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import logo2 from "../assets/images/cardtap-white.png"
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation } from "react-router-dom";
import { faPeopleRoof } from '@fortawesome/free-solid-svg-icons';
import useLogout from "../hooks/useLogout";
import { useUserContext } from "../hooks/useUserContext";
import { useProfileContext } from "../hooks/useProfileContext";
export default function RootLayout() {
    const { user } = useUserContext()
    const { dispatch } = useProfileContext();
    const { isUser } = useParams();
    const [pokeOpen, setPokeOpen] = useState(false);
    const handlePokeOpen = () => setPokeOpen(true);
    const handlePokeClose = () => setPokeOpen(false);
    const [clickTutorial, setClickTutorial] = useState([false,'desktop'])
    const { logout } = useLogout();

    const handleClick = () => {
        logout()
    }


    const [navbar, setNavbar] = useState(false);
    const handleNavOpen = () => {
        setNavbar(!navbar);
    }

    const location = useLocation();
    const [defaultLoadingAnalytics, setDefaultLoadingAnalytics] = useState(false)
    useEffect(() => {
        if (location.pathname === `/UserDashboard/${isUser}`) setDefaultLoadingAnalytics(true)
    })
    const handleLinkClick = () => {
        setDefaultLoadingAnalytics(false)
    }

    const handleStartTutorial = (view) => {
        setClickTutorial([true,view]);
        if(view==='mobile')
            handlePokeClose()

        setInterval(()=>{
            setClickTutorial([false,view]);
        },1000)
        console.log("Start Tutorial button clicked");
    }

    return (
        <div className="background">
            <div className="container">
                <div className="dashboardContainer">
                    <div className="navBurgerHolder">
                        <Button style={{ 'color': 'black', 'justifyContent': 'flex-start' }} className='taskOpenBtn' onClick={handlePokeOpen}>
                            <span className="navBurger">  <FontAwesomeIcon icon={faBars} size="xl" /> </span>
                        </Button>
                        <div className="dashboardImgHolder">
                            <img src={logo} />
                        </div>
                    </div>

                    <Modal open={pokeOpen} onClose={handlePokeClose}>
                        <Box >
                            <div style={{ 'fontFamily': 'poppins' }} className="dashboardNavbar">
                                <div className="dashboardNavbar-img"> <img src={logo2} /> </div>
                                <span onClick={e=>handleStartTutorial('mobile')} className="tutorial-button"> Start Tutorial</span>
                                <ul className="dashboardNavbar-list" onClick={handlePokeClose}>
                                    {isUser !== 'Main' &&
                                        <li> <NavLink className="link-dashboardNavbar" to={"/CorporateDashboard/" + isUser}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faPeopleRoof} /></span><span className="text-dashboardNavbar">Back To Dashboard</span></NavLink></li>
                                    }
                                    <li><NavLink className={defaultLoadingAnalytics ? "link-dashboardNavbar active" : "link-dashboardNavbar"} to={`/UserDashboard/${isUser}/`}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faChartSimple} /></span><span className="text-dashboardNavbar">Analytics</span></NavLink></li>
                                    <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/" + isUser + "/ProfileView"} onClick={handleLinkClick}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faUsers} /></span ><span className="text-dashboardNavbar">Profiles</span> </NavLink></li>
                                    <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/" + isUser + "/SocialMedia"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faShareNodes} /></span><span className="text-dashboardNavbar">Social Media</span></NavLink></li>
                                    <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/" + isUser + "/BusinessInfo"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faFileLines} /></span><span className="text-dashboardNavbar">Business Info</span></NavLink></li>
                                    <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/" + isUser + "/contactinfo"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faAddressBook} /></span><span className="text-dashboardNavbar">Personal Info</span></NavLink></li>
                                    <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/" + isUser + "/contacts"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faLink} /></span><span className="text-dashboardNavbar">My Contacts </span></NavLink></li>

                                </ul>
                                <button onClick={handleClick} className="logout-btn"> Logout </button>
                            </div>
                        </Box>
                    </Modal>

                    <div className="desktopDashboardNavbar">
                        <span onClick={e=>handleStartTutorial('desktop')} className="tutorial-button"> Start Tutorial</span>
                        <DashboardNavbar isUser={isUser} />
                    </div>
                    <div className="dashboardContent">
                        <Outlet context={[clickTutorial]} />
                    </div>
                </div>
            </div>
        </div>
    )
}