import logo from "../assets/images/cardtap-white.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink,useLocation } from "react-router-dom";
import { faPeopleRoof} from '@fortawesome/free-solid-svg-icons';

import useLogout from "../hooks/useLogout";
import { useEffect, useState } from "react";
import { useUserContext } from "../hooks/useUserContext";
import { useProfileContext } from "../hooks/useProfileContext";

const DashboardNavbar = ({isUser}) => {
    const {user} = useUserContext()
    const {dispatch} = useProfileContext() 
    const {logout} = useLogout();
    const handleClick = () =>{
        logout()
    }
    const location = useLocation();
    const [defaultLoadingAnalytics,setDefaultLoadingAnalytics] = useState(false)
    useEffect(()=>{
        if(location.pathname=== `/UserDashboard/${isUser}`) setDefaultLoadingAnalytics(true)
    })
    const handleLinkClick =()=>{
        setDefaultLoadingAnalytics(false)
    }
    return (
        <div style={{'fontFamily':'poppins'}} className="dashboardNavbar">
            <div className="dashboardNavbar-img"> <img src={logo} /> </div>
            <ul className="dashboardNavbar-list">
                {isUser !=='Main' && 
                    <li> <NavLink className="link-dashboardNavbar" to={"/CorporateDashboard/"+isUser} > <span className="icons-dashboardNavbar" ><FontAwesomeIcon icon={faPeopleRoof}  /></span><span className="text-dashboardNavbar">Back To Dashboard</span></NavLink></li>
                }
                <li><NavLink className={defaultLoadingAnalytics?"link-dashboardNavbar active":"link-dashboardNavbar"} to={`/UserDashboard/${isUser}/`} > <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faChartSimple}  /></span><span className="text-dashboardNavbar">Analytics</span></NavLink></li>
                <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/"+isUser+"/ProfileView"} onClick={handleLinkClick}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faUsers}  /></span ><span className="text-dashboardNavbar">Profiles</span> </NavLink></li>
                <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/"+isUser+"/SocialMedia"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faShareNodes}  /></span><span className="text-dashboardNavbar">Social Media</span></NavLink></li>
                <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/"+isUser+"/BusinessInfo"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faFileLines}  /></span><span className="text-dashboardNavbar">Business Info</span></NavLink></li>
                <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/"+isUser+"/contactinfo"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faAddressBook}  /></span><span className="text-dashboardNavbar">Personal Info</span></NavLink></li>
                <li><NavLink className="link-dashboardNavbar" to={"/UserDashboard/"+isUser+"/contacts"} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faLink}  /></span><span className="text-dashboardNavbar">My Contacts</span></NavLink></li>
            </ul>
            <button onClick={handleClick} className="logout-btn"> Logout </button>
        </div>
    );
}
export default DashboardNavbar