import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ThankYouPage = () => {
    const { paymentStatus, merchantOrderId } = useParams()
    const [orderData, setOrderData] = useState()
    console.log(paymentStatus, merchantOrderId)
    let message='', secondMessage='';
    if (paymentStatus === 'SUCCESS' || paymentStatus === 'COD') {
        message = 'Thank you For Ordering'
        secondMessage = 'Kindly Check your email for further details.'
    }
    else {
        message = 'Payment was unsuccessfull, please try again.'
    }
    useEffect(() => {
        const sendOrderMessage = async () => {
            const formData = new FormData();
            formData.append('orderId', merchantOrderId);
            try {
                await axios.post(`/users/kashierOrderFinalize`, formData, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(json)
                        setOrderData(json.order)
                    })
            }
            catch (err) {

            }
        }
        const sendOrderMessageCOD = async () => {
            const formData = new FormData();
            formData.append('orderId', merchantOrderId);
            try {
                await axios.get(`/users/order/${merchantOrderId}`, formData, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(json)
                        setOrderData(json.order)
                    })
            }
            catch (err) {
                console.log(err)
            }
        }
        if(paymentStatus === 'SUCCESS'){
            sendOrderMessage()
        }
        else if(paymentStatus === 'COD'){
            sendOrderMessageCOD()
        }
        window.scrollTo(0, 0);
    }, []); // Empty dependency array to run this effect only once when the component mounts
    return (
        <div className="thankYouContainer">
            <div>
                <h1 className="Title">{message} </h1>
            </div>
            <div className="thank-you-container">
                <div className="order-details">
                    { orderData && <div className="order-info">
                        <h2 className='checkOutHeader'>Order Information:</h2>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Order ID:</strong> </p> 
                            <p> {orderData._id}</p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>First Name:</strong> </p> 
                            <p> {orderData.name}</p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Email:</strong> </p> 
                            <p> {orderData.email}</p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Phone Number:</strong> </p> 
                            <p> {orderData.phoneNumber}</p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Address:</strong> </p> 
                            <p> {orderData.address} </p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Apt:</strong> </p> 
                            <p> {orderData.apt} </p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Floor:</strong> </p> 
                            <p> {orderData.floor} </p>  
                        </div>
                        <div className='detailsBoxCheckout'>
                            <p style={{'fontSize':'20px'}}> <strong>Price:</strong> </p> 
                            <p> {orderData.totalPrice} </p>  
                        </div>
                    </div>}
                </div>
            </div>
            <div>
                <h2 className="checkMail">{secondMessage}</h2>

            </div>
        </div>
    );
}

export default ThankYouPage