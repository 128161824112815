import React, { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import loginImg from '../assets/images/login.png'
import logoGrad from '../assets/images/logo2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Navigate, useLoaderData, useNavigate, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
const ResetPassword = () => {
    const isVerified = useLoaderData();
    const navigate = useNavigate()
    const {id,token} = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState("")
    console.log(isVerified)
    if(isVerified.status === 'invalid'){
        return <Navigate to={'/login'} replace={true} />
    }

   
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        formData.append('password', newPassword);
        formData.append('passwordConfirm', confirmPassword);
        await axios.post(`/users/reset-password/${id}/${token}`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
                if(json.status === 'Password updated successfuly'){
                    setMessage("Password has been changed successfully, and you're being redirected to login")
                    setAlert("success")
                    setTimeout(()=>{
                        navigate('/login')
                    },5000)
                }
                else{
                    setMessage("Error has been occurred, please try again.")
                    setAlert("success")
                }
                
            })
            setIsLoading(false)
    };

    const handleToggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="loginHolder">
            <div className="loginContainer">
                <div className="login-img" style={{ 'background': `url(${loginImg})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center', 'backgroundRepeat': 'no-repeat' }} />
                <form className="loginForm" onSubmit={handleSubmit}>
                    <div className="login-part1"> <img src={logoGrad} /> </div>

                    <div className="login-part2">
                        <h3 style={{ 'fontFamily': 'poppins' }}>Change Your Password</h3>
                        {message && <Alert severity={alert}>{message}</Alert>}
                        <div className="login-input">
                            <label>Set New Password</label>
                            <div className="password-input">
                                <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    placeholder="Enter New Password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    value={newPassword}
                                />
                                <span
                                    className={`password-toggle ${showNewPassword ? 'visible' : ''}`}
                                    onClick={handleToggleNewPassword}
                                >
                                    <FontAwesomeIcon icon={faEye} style={{ color: "#453a3a" }} />
                                </span>
                            </div>
                        </div>

                        <div className="login-input">
                            <label>Confirm Password</label>
                            <div className="password-input">
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    value={confirmPassword}
                                />
                                <span
                                    className={`password-toggle ${showConfirmPassword ? 'visible' : ''}`}
                                    onClick={handleToggleConfirmPassword}
                                >
                                    <FontAwesomeIcon icon={faEye} style={{ color: "#453a3a" }} />
                                </span>
                            </div>
                        </div>

                        <button type="submit">{!isLoading?'Reset Password':<CircularProgress />}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;

export const CheckToken = async ({ params }) => {
    const { id , token} = params
    const res = await fetch(`/users/reset-password/${id}/${token}`,{method:'GET'})
    return res;
}