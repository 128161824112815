import { useState, useEffect } from 'react';
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from "react-router-dom";
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import section3Img from "../assets/images/desc2.png"
import section3Img2 from "../assets/images/desc1.png"
import { useCartContext } from '../hooks/useCartContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from 'swiper/modules';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//POPUP
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import whiteCard from '../assets/images/1.png'
import blackCard from '../assets/images/2.png'
import gradCard from '../assets/images/3.png'
import summerCard from '../assets/images/4.png'
import whiteTag from '../assets/images/t1.png'
import blackTag from '../assets/images/t2.png'
import gradTag from '../assets/images/t3.png'
import summerTag from '../assets/images/t4.png'
import metalB from '../assets/images/blackM.png'
import metalG from '../assets/images/goldM.png'
const ProductDescriptionPage = () => {
    const [product, setProduct] = useState();
    const [products, setProducts] = useState();
    const [prodQuantity, setProdQuantity] = useState(1)
    const [card,setCard] = useState(null);
    const [tag,setTag] = useState(null);
    const [chosenOffer,setChosenOffer] = useState()
    const [price, setPrice] = useState()
    const { name } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const { cart, dispatch } = useCartContext();
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [open, setOpen] = useState(false);
    const [pokeOpen, setPokeOpen] = useState(false);
    const handlePokeOpen = () => setPokeOpen(true);
    const handlePokeClose = () => setPokeOpen(false);
    console.log(cart)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isInitialRender) {
            window.scrollTo(0, 0);
            setIsInitialRender(false); // Update the state to indicate that it's no longer the initial render
        }
        setIsLoading(true)
        localStorage.setItem('cart', JSON.stringify(cart));
        const getProduct = async () => {
            try {
                const response = await fetch(`/products/viewProduct/${name}`)
                const json = await response.json()
                setPrice(json.price)
                setProduct(json)
                setChosenOffer(json.name === 'Cardtap NFC Business Metal Card & Tag Offer'?1:2)
                const res2 = await fetch(`/products/getProducts`)
                let json2 = await res2.json()
                json2 = [...(json2.filter((x, idx) => (idx % 2 != 0 && x._id != json._id)))]
                setProducts(json2)
                setIsLoading(false)
            }
            catch (err) {
                console.log("No internet connection")
            }
        }
        getProduct()

    }, [cart, name], isInitialRender)



    const handleImgUrl = (url, imgNo) => {
        let prodObj = {
            _id: product._id,
            price: product.price,
            name: product.name,
            URL_Name: product.URL_Name,
            imgLink: url,
            otherImgsLinks: product.otherImgsLinks,
            description: product.description,
            sale: product.sale
        }
        setProduct(prodObj);
    }
    const handleMinus = (data) => {
        if (prodQuantity > 1) {
            console.log(prodQuantity)
            setPrice(parseInt(product.price) * (parseInt(prodQuantity) - 1))
            setProdQuantity(prodQuantity - 1)
        }
    }
    const handlePlus = (data) => {
        setPrice(parseInt(product.price) * (parseInt(prodQuantity) + 1))
        setProdQuantity(prodQuantity + 1)
    }
    const [addClicked, setAddClicked] = useState(null)

    const handleAddToCart = (data,e) => {
        e.preventDefault()
        let arr = cart;
        let itemIndex = arr.findIndex(x => x._id == data._id)
        if (itemIndex > -1) {
            arr[itemIndex].quantity += prodQuantity
            arr[itemIndex].imgLink = arr[itemIndex].otherImgsLinks[0]
            dispatch({ type: 'UPDATE_ITEM', payload: arr });
        }
        else {
            console.log(tag)
            data.quantity = prodQuantity
            if(tag){
                console.log("heree")
                data.offer = [tag,card]
            }
            dispatch({ type: 'ADD_ITEM', payload: data });
        }
        swal({
            title: "Added to cart successfuly",
            icon: "success",
            button: "Continue shopping",
        });
        setTimeout(() => {
            setAddClicked(false)
        }, 1000);
        handlePokeClose()
    }
    const calculatePriceAfterSale = () => {
        let price = parseInt(product.price) + 1
        let sale = product.sale / 100
        return price - (price * sale) - 1

    }

    return (
        <div className="productDescriptionContainer">
            {isLoading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Modal open={pokeOpen} onClose={handlePokeClose}>
                <Box >
                    <div className='offer-container' style={{ 'overflow': 'hidden', 'borderRadius': '18px' }}>
                        <form style={{ 'fontFamily': 'poppins' }} className="offer-holder" onSubmit={e=>handleAddToCart(product,e)}>
                            {chosenOffer === 2 && <h4 style={{ 'fontSize': '25px', 'borderBottom':'1px solid black','width':'fit-content' }}> Choose the card you want</h4>}
                            {chosenOffer === 2 && <div className='cards-offer'>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input required type="radio" name="test" value="white card" onClick={(e) => setCard('White Card')} />
                                    <img src={whiteCard} alt="Option 1" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test" value="black card"  onClick={(e) => setCard('Black Card')}/>
                                    <img src={blackCard} alt="Option 2" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test" value="grad card" onClick={(e) => setCard('Gradient Card')} />
                                    <img src={gradCard} alt="Option 3" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test" value="summer card" onClick={(e) => setCard('Summer Card')} />
                                    <img src={summerCard} alt="Option 4" />
                                </label>

                            </div>}
                            {chosenOffer === 1 && <div className='cards-offer'>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test" value="black metal card" onClick={(e) => setCard('Black Metal Card')} />
                                    <img style={{'width':'50%'}} src={metalB} alt="Option 1" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test" value="gold metal card" onClick={(e) => setCard('Gold Metal Card')} />
                                    <img style={{'width':'50%'}} src={metalG} alt="Option 2" />
                                </label>

                            </div>}
                            <h4 style={{ 'fontSize': '25px' , 'borderBottom':'1px solid black','width':'fit-content'}}> Choose the tag you want</h4>
                            <div className='tags-offer'>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input required type="radio" name="test2" value="white Tag" onClick={(e) => setTag('White Tag')} />
                                    <img src={whiteTag} alt="Option 1" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test2" value="black Tag" onClick={(e) => setTag('Black Tag')} />
                                    <img src={blackTag} alt="Option 2" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test2" value="grad Tag" onClick={(e) => setTag('Gradient Tag')} />
                                    <img src={gradTag} alt="Option 3" />
                                </label>
                                <label style={{ 'display': 'flex', 'flexDirection': 'column-reverse', 'alignItems': 'center', 'gap': '10px' }}>
                                    <input type="radio" name="test2" value="White-Gardient Tag"  onClick={(e) => setTag('White-Gardient Tag')} />
                                    <img src={summerTag} alt="Option 4" />
                                </label>

                            </div>
                            <button
                                type='submit'
                                className=" offer-addToCart prod-addToCart"
                            >
                                Add to cart
                            </button>
                        </form>
                    </div>
                </Box>
            </Modal>
            {
                product && <div style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center', 'justifyContent': 'center' }}>
                    <div className="product-desc-section1">
                        <div className="product-desc-section1-left">
                            <div className="prod-img" >
                                {product && <img src={product.imgLink} />}
                            </div>

                            <div className="prod-other-images mobile">
                                {product && product.otherImgsLinks.map((img, index) => (
                                    <img key={index} src={img} onClick={() => handleImgUrl(img, 1)} style={{ 'border': (product.imgLink == img) && '1px solid black' }} />
                                ))}
                            </div>
                        </div>
                        <div className="product-desc-section1-right">
                            <h3> {product.name} </h3>
                            <div className="descContainer">
                                {product.description.map((prod, index) => (
                                    <p key={index}> {prod} </p>
                                ))}
                            </div >
                            {product.sale == 0 ?
                                <p style={{ 'margin': '40px 0', 'fontSize': '20px' }}>{parseInt(price).toFixed(2)} LE</p>
                                :
                                <div className='priceAndSaleContainer-Description'>
                                    <p className='custom-underline' style={{ 'margin': '40px 0', 'fontSize': '20px', opacity: '0.5' }}>{parseInt(price).toFixed(2)} LE</p>
                                    {/* <div className="line-Description"> </div> */}
                                    <p className="productPrice-Description" style={{ color: 'red', fontSize: '20px', margin: '40px 0' }}> {(calculatePriceAfterSale() * prodQuantity).toFixed(2)} LE</p>
                                </div>
                            }
                            {
                                product._id != "651b3dfa2b4af7127cb8ef4c" ?
                                    <div className="actionContainer">
                                        {(product.name !== 'Cardtap NFC Business Metal Card & Tag Offer' && product.name !== 'Cardtap NFC Business Card & Tag Bundle Offer')&&<span>Quantity</span>}
                                        <div className="quantityButtons">
                                            <div className='actionButtons'>
                                               {(product.name !== 'Cardtap NFC Business Metal Card & Tag Offer' && product.name !== 'Cardtap NFC Business Card & Tag Bundle Offer')&& <div className='actionButtonsHolder'>
                                                    <FontAwesomeIcon size='2x' onClick={handleMinus} icon={faMinus} />
                                                    <span style={{ 'fontSize': '20px' }}> {prodQuantity} </span>
                                                    <FontAwesomeIcon size='2x' onClick={handlePlus} icon={faPlus} />
                                                </div>}
                                                <Link
                                                    className={addClicked === product._id ? "button-ProductDescription-clicked" : "prod-addToCart"}
                                                    disabled={addClicked === product._id}
                                                    onClick={(e) => {
                                                        if (product.name === 'Cardtap NFC Business Metal Card & Tag Offer' || product.name === 'Cardtap NFC Business Card & Tag Bundle Offer' ) {
                                                            handlePokeOpen()
                                                        }
                                                        else {
                                                            setAddClicked(product._id);
                                                            handleAddToCart(product,e);
                                                        }
                                                    }}
                                                >
                                                    Add to cart
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    <div className="actionContainer">
                                        <div className="quantityButtons">
                                            <Link className="prod-buy" onClick={handleClickOpen}> Customize your product </Link>
                                            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { padding: '40px', borderRadius: '40px' } }}>
                                                <DialogTitle>Contact us to get your customized card</DialogTitle>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={handleClose}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 8,
                                                        top: 8,
                                                        color: (theme) => theme.palette.grey[500],
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        You can contact us via WhatsApp, Mobile, Email
                                                    </DialogContentText>
                                                    <List sx={{ paddingTop: '10px' }}>
                                                        <ListItem>
                                                            <ListItemButton href="https://wa.me/201080124390" target="_blank">
                                                                <ListItemAvatar>
                                                                    <Avatar className='popupAvatar'>
                                                                        <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#000000" }} />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText className='popupText' primary={<span className='popupText'>Whatsapp</span>} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemButton href="tel:+201080124390" target="_blank">
                                                                <ListItemAvatar>
                                                                    <Avatar className='popupAvatar'>
                                                                        <FontAwesomeIcon icon={faPhone} style={{ color: "#000000" }} />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText className='popupText' primary={<span className='popupText'>Phone</span>} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemButton href="mailto:Info@card-tap.com" target="_blank">
                                                                <ListItemAvatar>
                                                                    <Avatar className='popupAvatar'>
                                                                        <FontAwesomeIcon icon={faEnvelope} style={{ color: "#000000" }} />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText className='popupText' primary={<span className='popupText'>Email</span>} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </List>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Cancel</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="prod-other-images desktop">
                        {product && product.otherImgsLinks.map((img, index) => (
                            <img key={index} src={img} onClick={() => handleImgUrl(img, 1)} style={{ 'border': (product.imgLink == img) && '1px solid black' }} />
                        ))}
                    </div>

                    <p className='other-prod-title'> You Might Also Like </p>

                    <div className="prod-desc-items-desktop">
                        <Swiper
                            pagination={true}
                            modules={[Pagination]}
                            spaceBetween={25}
                            slidesPerView={4}

                        >
                            {
                                products && products.map((prod, idx) => (
                                    <SwiperSlide className='swiper-slide-2' key={idx}>
                                        <div className="productCard">
                                            <Link to={`/products/${prod.URL_Name}`} className="productImgHolder-home">
                                                <img src={prod.imgLink} alt="Cardtap's digital NFC business card" />
                                            </Link>
                                            <p>{prod.name}</p>
                                            <Link
                                                className={addClicked === prod._id ? "button-HomePage-clicked" : "section6-button"}
                                                disabled={addClicked === prod._id}
                                                onClick={(e) => {
                                                    setAddClicked(prod._id); // Set addClicked to the product's id
                                                    handleAddToCart(prod);
                                                }}
                                            >
                                                Add to cart
                                            </Link>
                                        </div>
                                        <div style={{ 'height': '100px' }} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                    <div className="prod-desc-items-mobile">
                        <Swiper className='swiper-desc'
                            spaceBetween={10}
                            slidesPerView={1.5}

                        >
                            {
                                products && products.map((prod, idx) => (
                                    <SwiperSlide key={idx}>
                                        <div className="productCard">
                                            <Link to={`/products/${prod.URL_Name}`} className="productImgHolder-home">
                                                <img src={prod.imgLink} alt="Cardtap's digital NFC business card" />
                                            </Link>
                                            <p>{prod.name}</p>
                                            <Link
                                                className={addClicked === prod._id ? "button-HomePage-clicked" : "section6-button"}
                                                disabled={addClicked === prod._id}
                                                onClick={(e) => {
                                                    setAddClicked(prod._id); // Set addClicked to the product's id
                                                    handleAddToCart(prod);
                                                }}
                                            >
                                                Add to cart
                                            </Link>                                    </div>
                                        <div style={{ 'height': '100px' }} />
                                    </SwiperSlide>
                                ))
                            }

                        </Swiper>
                    </div>
                    <div className="product-desc-section3">
                        <div className="upperPart">
                            <div className="left">
                                <h2>Turn your interactions into <span> sales </span> </h2>
                                <p>Durable and Convenient</p>
                            </div>
                            <div className="right">
                                <img src={section3Img} />
                            </div>
                        </div>
                    </div>
                    <div className="product-desc-section3">
                        <div className="lowerPart">
                            <div className="right">
                                <img src={section3Img2} />
                            </div>
                            <div className="left">
                                <h2>Elevate Your  <span> Brand </span> </h2>
                                <p>Beyond Boundaries</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div >
    );
}

export default ProductDescriptionPage

export const GetProductDetails = async ({ params }) => {
    console.log(params)
    const { id } = params
    let json
    try {
        const response = await fetch(`/products/viewProduct/${id}`)
        json = await response.json()
    }
    catch (err) {
        console.log("No internet connection")
    }
    return json;
}