import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from 'axios';
import logoGrad from '../assets/images/logo2.png'
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserContext } from "../hooks/useUserContext";
import { useProfileContext } from "../hooks/useProfileContext";

const NewAccountPage = () => {
    const { id } = useParams()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState('')
    const { dispatch } = useUserContext()
    const { dispatch: update } = useProfileContext()
    const navigate = useNavigate();

    useEffect(() => {
        const checkAccount = async () => {
            try {
                await axios.get('/users/checkUser/' + id, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    }
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(json)
                        if (json.name !== 'null' && json.name !== ' ') {
                            navigate('/users/id/' + id);
                        }
                    })
            }
            catch (err) {
                console.log(err)
            }
        }
        checkAccount()
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Email', email);
        formData.append('Name', name);
        formData.append('Password', password);
        await axios.post(`/users/newProfileInfoSave`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                if (res.data.msg) {
                    setError('Email is already registered.')
                }
                else {
                    console.log(res.data)
                    localStorage.setItem('user', JSON.stringify(res.data))
                    dispatch({ type: 'LOGIN', payload: res.data })
                    update({ type: 'SET_PROFILES', payload: res.data.user })
                    navigate('/login')
                }
                setIsLoading(false)
            })

    }

    return (
        <div className="newAcc loginContainer">
            <form className="loginForm newAccLoginForm" onSubmit={handleSubmit}>
                <div className="login-part1"> <img src={logoGrad} alt="Account" /> </div>
                <div className="login-part2">
                    <h3 style={{ 'fontFamily': 'poppins', 'textAlign': 'center' }}>Welcome to Cardtap family</h3>
                    {error && <Alert severity="error">{error}</Alert>}
                    <div className="login-input">
                        <label>Name</label>
                        <div className="password-input">
                            <input
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                pattern="^[a-zA-Z ]*$"
                                required
                            />
                        </div>
                    </div>
                    <div className="login-input">
                        <label>Email</label>
                        <div className="password-input">
                            <input
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                            />
                        </div>
                    </div>
                    <div className="login-input">
                        <label>Password</label>
                        <div className="password-input">
                            <input
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                        </div>
                    </div>
                    <div className="login-input">
                        <label>Confirm Password</label>
                        <div className="password-input">
                            <input
                                type="password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                required
                            />
                        </div>
                    </div>
                    <button type="submit" >{!isLoading && 'Login'} {isLoading && <CircularProgress />}</button>


                </div>
            </form>
        </div>
    );
}
export default NewAccountPage