import { useEffect, useState, React } from "react";
import { useCartContext } from "../hooks/useCartContext";
import CheckoutItem from "../components/CheckoutItem";
// import { useUserContext } from "../hooks/useUserContext";
import { useNavigate } from "react-router-dom"
import { PhoneInput } from 'react-international-phone';
import CircularProgress from '@mui/material/CircularProgress';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import visa from '../assets/images/visa.png';
import miza from '../assets/images/miza.png';
import mastercard from '../assets/images/mastercard.png';
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};
const Checkout = () => {
    const { cart, dispatch } = useCartContext();
    // const {user} = useUserContext();
    const [isLoading, setIsLoading] = useState(false)
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [address, setAddress] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setphoneNumber] = useState();
    const [apt, setApt] = useState();
    const [floor, setFloor] = useState();
    const [method, setMethod] = useState();
    const [totalItemsQuantity, setTotalItemsQuantity] = useState(0);
    const [totalItemsPrice, setTotalItemsPrice] = useState(0);
    const [items, setItems] = useState([])
    const [redirectionLink, setRedirectionLink] = useState('')
    const navigate = useNavigate();
    let isValid = isPhoneValid(phoneNumber);

    const calculatePriceAfterSale = (price, sale) => {
        let checkout_price = parseInt(price) + 1
        let checkout_sale = sale / 100
        return checkout_price - (checkout_price * checkout_sale) - 1
    }
    useEffect(() => {
        let x = 0, y = 0;
        if (cart) {
            for (let i = 0; i < cart.length; i++) {
                let price = cart[i].price
                let sale = cart[i].sale
                let quantity = cart[i].quantity
                if (sale > 0) {
                    price = calculatePriceAfterSale(price, sale)
                }
                else {
                    // const p = cart[i].price.split(" ")
                    price = parseInt(price);
                }
                x += quantity;
                y += price * quantity
                setItems([...items, cart[i].name])
            }
            setTotalItemsPrice(y);
            setTotalItemsQuantity(x);
        }
    }, [cart])

    const handleOrder = async (price, e) => {
        e.preventDefault();
        setIsLoading(true)
        if (method === 'COD') {
            console.log(fname, lname, address, apt, method, email, floor, phoneNumber)
            const formData = new FormData();
            formData.append('price', price);
            formData.append('fName', fname);
            formData.append('lName', lname);
            formData.append('address', address);
            formData.append('apt', apt);
            formData.append('method', method);
            formData.append('email', email);
            formData.append('floor', floor);
            formData.append('phoneNumber', phoneNumber);
            for (let i = 0; i < cart.length; i++) {
                let price = cart[i].price
                let sale = cart[i].sale
                if (sale > 0) {
                    cart[i].price = calculatePriceAfterSale(price, sale)
                }
            }
            formData.append('cart', JSON.stringify(cart));
            console.log(JSON.stringify(cart))
            try {
                const response = await fetch('/users/placeOrder',
                    {
                        method: 'POST',
                        body: formData
                    }
                )

                const json = await response.json()
                const id = json.order._id
                console.log(json);
                console.log(id)
                if (response.ok) {
                    // dispatch({type: 'UPDATE_PROFILES',payload: json})
                    navigate(`/thankYou/COD/${id}`)
                    dispatch({ type: 'EMPTY_CART' })
                    // navigate(`profile/${json.profiles.length-1}`)
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            try {
                console.log("hereeree")
                const formData = new FormData();
                formData.append('price', price);
                formData.append('fName', fname);
                formData.append('lName', lname);
                formData.append('address', address);
                formData.append('apt', apt);
                formData.append('floor', floor);
                formData.append('email', email);
                formData.append('phoneNumber', phoneNumber);
                for (let i = 0; i < cart.length; i++) {
                    let price = cart[i].price
                    let sale = cart[i].sale
                    if (sale > 0) {
                        cart[i].price = calculatePriceAfterSale(price, sale)
                    }
                }
                formData.append('cart', JSON.stringify(cart));
                await axios.post(`/users/kashierOrderSetup`, formData, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest"
                    },
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(res.data)
                        window.location.href = res.data.Link
                    })
            }
            catch (err) {
                console.log(err)
            }
        }
        setIsLoading(false)
    }


    const handleKashier = async (price, type) => {
        if (type === 'G') {
            setMethod('G')

            setIsLoading(true)
            try {
                const formData = new FormData();
                formData.append('price', price);
                formData.append('fName', fname);
                formData.append('lName', lname);
                formData.append('address', address);
                formData.append('apt', apt);
                formData.append('floor', floor);
                formData.append('email', email);
                formData.append('phoneNumber', phoneNumber);
                for (let i = 0; i < cart.length; i++) {
                    let price = cart[i].price
                    let sale = cart[i].sale
                    if (sale > 0) {
                        cart[i].price = calculatePriceAfterSale(price, sale)
                    }
                }
                formData.append('cart', JSON.stringify(cart));
                await axios.post(`/users/kashierOrderSetup`, formData, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest"
                    },
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(res.data)
                        setRedirectionLink(res.data.Link)
                        setIsLoading(false)
                    })
            }
            catch (err) {
                console.log(err)
            }
        }
        else {
            setMethod('COD')
            setRedirectionLink('')
        }
    }

    return (
        <div className="checkoutContainer">
            <div className="checkoutHolder">
                <h1>Checkout</h1>
                {isLoading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}
                <div className="checkoutHeader">
                    <div className="checkout-leftPart">
                        <div className="left-upper-part">
                            <form onSubmit={e => handleOrder(totalItemsPrice, e)} id="checkout-form" className="address-form">
                                <div className="form-div">
                                    <div className="form-part1">
                                        <label>First Name<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const isOnlyLettersAndHyphens = /^[A-Za-z-]+$/.test(inputValue);

                                                if (isOnlyLettersAndHyphens || inputValue === "") {
                                                    setFname(inputValue);
                                                }
                                            }}
                                            value={fname}
                                            required
                                        />
                                    </div>
                                    <div className="form-part1">
                                        <label>Last Name<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const isOnlyLettersAndHyphens = /^[A-Za-z-]+$/.test(inputValue);

                                                if (isOnlyLettersAndHyphens || inputValue === "") {
                                                    setLname(inputValue);
                                                }
                                            }}
                                            value={lname}
                                            required
                                        />
                                    </div>
                                </div >
                                <div className="form-div">
                                    <div className="form-part3">
                                        <label>Phone Number<span style={{ color: 'red' }}>*</span></label>
                                        <PhoneInput
                                            className="phoneInput"
                                            defaultCountry="eg"
                                            value={phoneNumber}
                                            onChange={(phone) => setphoneNumber(phone)}
                                            minLength="11" // Set the minimum length to 6 characters
                                            required
                                            forceDialCode={true}
                                        />
                                        {/* <input
                                            type="text"
                                            pattern="[0-9]*" // Allow only numbers
                                            onChange={(e) => setphoneNumber(e.target.value)}
                                            minLength="6" // Set the minimum length to 6 characters
                                            required
                                        /> */}
                                    </div>
                                </div>
                                <div className="form-div">

                                    <div className="form-part3">
                                        <label>Email<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            required
                                        />
                                    </div>

                                </div>
                                <div className="form-div">
                                    <div className="form-part3">
                                        <label>Shipping Address<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            onChange={(e) => setAddress(e.target.value)}
                                            value={address}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-div">
                                    <div className="form-part1">
                                        <label>Apt. Number (Optional)</label>
                                        <input
                                            type="number"
                                            onChange={(e) => {
                                                // Ensure the value is not negative
                                                const newValue = parseInt(e.target.value, 10);
                                                if (newValue < 0) {
                                                    setApt(0); // Set to 0 if negative value is entered
                                                } else {
                                                    setApt(newValue);
                                                }
                                            }} value={apt}
                                            min="0"
                                        />
                                    </div>
                                    <div className="form-part1">
                                        <label>Floor (Optional)</label>
                                        <input
                                            type="number"
                                            onChange={(e) => {
                                                // Ensure the value is not negative
                                                const newValue = parseInt(e.target.value, 10);
                                                if (newValue < 0) {
                                                    setFloor(0); // Set to 0 if negative value is entered
                                                } else {
                                                    setFloor(newValue);
                                                }
                                            }}
                                            value={floor}
                                            min="0"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="left-lower-part">
                            <p>Payment Method</p>
                            <div>
                                <label className="method" htmlFor="geidea">
                                    <input onClick={e=>setMethod('VISA')} disabled={fname && lname && address && email && phoneNumber ? false : true} form="checkout-form" style={{ 'width': '25px', 'height': '25px' }} id="geidea" type="radio" name="paymentMethod" value="Geidea" required />
                                    Pay By Credit/Debit Card
                                    <div className="visa-imgs">
                                        <img src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg' />
                                        <img src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/5e3b05b68f3d31b87e84.svg' />
                                        <img src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/c49d99653cb23e278a00.svg' />
                                    </div>
                                </label>
                            </div>

                            <div>
                                <label className="method" htmlFor="cod">
                                    <input onClick={e=>setMethod('COD')} form="checkout-form" style={{ 'width': '25px', 'height': '25px' }} id="cod" type="radio" name="paymentMethod" value="Cash On Delivery" required />
                                    Cash On Delivery
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className="checkout-rightPart">
                        {
                            cart.map((item, idx) => (
                                <CheckoutItem item={item} key={idx} />
                            ))
                        }
                        <div className="product-policy">
                            By placing your order, you agree to our company Privacy policy and Conditions of use.
                        </div>
                        <div className="horizontal-line"></div>
                        {cart && <div className="orderSummary">
                            <h3> Order Summary </h3>
                            <div className="summaryItem">
                                <p>items ({totalItemsQuantity})</p>
                                <p>{totalItemsQuantity}</p>
                            </div>
                            <div className="summaryItem">
                                <p>Shipping and handling</p>
                                <p>Free</p>
                            </div>
                        </div>}
                        <div className="horizontal-line"></div>
                        <div className="summaryItem price">
                            <p>Total Price: {totalItemsPrice.toFixed(2)} EGP</p>
                        </div>


                        <button form="checkout-form" disabled={!isValid || cart.length === 0} className="placeOrder"> Place Order </button>

                    </div>
                </div>
            </div>
        </div>
    );

}
export default Checkout;
