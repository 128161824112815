
import { Navigate, createBrowserRouter, RouterProvider, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import ProductsPage, { GetProducts } from './pages/ProductsPage';
import BusinessPage from './pages/BusinessPage';
import ProductDescriptionPage from './pages/ProductDescriptionPage';
import Login from './pages/Login';
import NewAccountPage from './pages/NewAccountPage';


import Analytics from './components/Analytics';
import Profiles from './components/Profiles';
import ProfileView from './components/ProfileView';
import { useUserContext } from './hooks/useUserContext';
import WebLayout from './layouts/WebLayout';
import ProfileLayout from './layouts/ProfileLayout';
import ErrorLayout from './layouts/ErrorLayout';
import UserDashboardLayout from './layouts/UserDashboardLayout';
import CorporateDashboardLayout from './layouts/CorporateDashboardLayout';

import SocialMedia from './components/SocialMedia';
import ContactInfo from './components/ContactInfo';
import BusinessInfo from './components/BusinessInfo';

import Profile, { ProfileDetailsLoader, OldProfileDetailsLoader } from './pages/Profile';
import Checkout from './pages/Checkout';
import MainPage from './components/Corporates/MainPage';
import EditEmployee from './components/Corporates/EditEmployee';
import CorporatesAnalytics from './components/Corporates/Analytics';
import Contacts from './components/Contacts';
import ThankYou from './pages/ThankYou';
import ResetPassword, { CheckToken } from './pages/ResetPassword';
import ForgetPassword from './pages/ForgetPassword';
import About from './pages/About';

const LoginRoute = () => {
  const { user, dispatch } = useUserContext();
  
  if(user == [] || user === null || user.email === undefined || user == {} || user === "undefined"){
    localStorage.removeItem('user')
    return <Login />
  }

  if (user && user.user.manager) {
    return <Navigate to={'/CorporateDashboard/' + user.user.company} />;

  }
  else if (user && !user.user.manager) {
    return <Navigate to={'/UserDashboard/Main'} />;
  }
  else {
    return <Login />
  }
}


const DashboardRoute = () => {
  const { user } = useUserContext();
  console.log(user)
  return user ? <UserDashboardLayout /> : <Navigate to='/login' />;
}

const CorporateDashboardRoute = () => {
  const { user } = useUserContext();
  const { companyName } = useParams();
  if (user && user.user.manager === companyName)
    return <CorporateDashboardLayout />
  else
    return <Navigate to='/login' />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <WebLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "Products",
        element: <ProductsPage />,
        loader: GetProducts
      },
      {
        path: "Business",
        element: <BusinessPage />,
      },
      {
        path: "Products/:name",
        element: <ProductDescriptionPage />,
      },
      {
        path: "Login",
        element: <LoginRoute />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "reset-password/:id/:token",
        element: <ResetPassword />,
        loader: CheckToken
      },
      {
        path: "thankYou/:paymentStatus/:merchantOrderId",
        element: <ThankYou />,
      },
      {
        path: "About",
        element: <About />,
      },
    ],
  },
  {
    path: "/users/id/",
    element: <ProfileLayout />,
    children: [
      {
        path: ":id",
        element: <Profile />,
        loader: ProfileDetailsLoader
      },
    ],
  },
  {
    path: "/users/",
    element: <ProfileLayout />,
    children: [
      {
        path: ':Name',
        element: <Profile />,
        loader: OldProfileDetailsLoader
      },
    ],
  },
  {
    path: "newUserInfo/:id",
    element: <ProfileLayout />,
    children: [
      {
        index: true,
        element: <NewAccountPage />,
      },
    ],
  },
  {
    path: "/UserDashboard/:isUser",
    element: <DashboardRoute />,
    children: [
      {
        index: true,
        element: <Analytics />
      },
      {
        path: "ProfileView",
        children: [
          {
            index: true,
            element: <Profiles />,
          },
          {
            path: "profile/:idx",
            element: <ProfileView />,
          },
        ]
      },
      {
        path: "socialmedia",
        element: <SocialMedia />,
      },
      {
        path: "contactinfo",
        element: <ContactInfo />,
      },
      {
        path: "businessinfo",
        element: <BusinessInfo />,
      },
      {
        path: "contacts",
        element: <Contacts />,
      },

    ],
  },
  {
    path: "CorporateDashboard/:companyName/",
    element: <CorporateDashboardRoute />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: "analytics",
        element: <CorporatesAnalytics />,
      },
      {
        path: "edit",
        element: <EditEmployee />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorLayout />
  }
]
)


function App() {
  return (
    <div>
      <Helmet>
        <title> Cardtap | Premium NFC Business Cards in Egypt</title>
        <meta name="description" content="Discover Cardtap's high-quality NFC business cards, now available in Egypt! Make networking easy and efficient with our NFC card solutions. Explore our range today." />
        <script src="https://cdn.jsdelivr.net/npm/driver.js@1.0.1/dist/driver.js.iife.js"></script>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/driver.js@1.0.1/dist/driver.css" />
      </Helmet>
      <RouterProvider router={router} />
    </div>
  );
}
export default App;