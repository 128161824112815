import { useOutletContext } from 'react-router'
import logo from  '../../assets/images/logo-16.png'
import EmployeeCard from './EmployeeCard'
import { useState } from 'react'
const MainPage = () =>{
    const employeesProfiles = useOutletContext()
    const [searchResults,setSearchResults] = useState(employeesProfiles)
    
    const handleSearch = (e) =>{
        console.log(e.target.value)
        if(e.target.value === "")
            setSearchResults(employeesProfiles)
        else
            setSearchResults([...(employeesProfiles.filter(w => w.name.toLowerCase().includes(e.target.value.toLowerCase()) ))])
    } 


    return(
        <div className="mainPageContainer">
            <div className='corp-img'>
                <img src={employeesProfiles[0].profiles[employeesProfiles[0].activeProfile].image1} />
            </div>
            <div className='searchBar'>
                <input onChange={e=>handleSearch(e)} type="text" placeholder='Search for an employee' />
            </div>
            <div className='cardsHolder'>
                <div className='cardsHolder-2'>
                    {searchResults.map((profile,idx)=>(
                        <EmployeeCard employee={profile} key={idx} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MainPage