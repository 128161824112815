import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { useCartContext } from '../hooks/useCartContext';
import CartItems from './CartItems';
import Logo from '../assets/images/logo.png'
import { useNavigate} from "react-router-dom"
const MobileNavbar = () =>{
    const { cart, dispatch } = useCartContext();
    const [pokeOpen, setPokeOpen] = useState(false);
    const handlePokeOpen = () => setPokeOpen(true);
    const handlePokeClose = () => setPokeOpen(false);

    const [cartOpen, setCartOpen] = useState(false);
    const handleCartOpen = () => setCartOpen(true);
    const handleCartClose = () => setCartOpen(false);
    const navigate = useNavigate();
    const backToHome = ()=>navigate('/')
    const cartItemsCount = () =>{
        let count = 0
        cart.forEach(item=>{
            count +=item.quantity
        })
        return count;
    }


    return(
        <div className='mobile-navbar'>
            <div className='mobile-navbar-buttons'>
                <Button style={{ 'color': 'black' }} className='taskOpenBtn' onClick={handlePokeOpen}>
                        <span>  <FontAwesomeIcon icon={faBars} size="lg" /> </span>
                </Button>

                <img src={Logo} alt="Cardtap's logo" onClick={backToHome}/>
                <div className='cart-mobile-container'>
                <Button style={{ 'color': 'black' }} className='taskOpenBtn' onClick={handleCartOpen}>
                        <FontAwesomeIcon className="" icon={faBagShopping} size="2xl" style={{ color: "#000000", }} />
                </Button>
                {cart.length >= 0 && (
                        <div className="cart-badge">
                            {cartItemsCount()}
                        </div>
                )}
                </div>
            </div>
            <Modal
                    open={cartOpen}
                    onClose={handleCartClose}
                >
                    <Box >
                        <div className='cartContainer'>
                            <div className="cartItemsHolder">
                                <h3 style={{'fontSize':'25px','marginBottom':'2%'}}>Shopping Cart</h3>
                                <h5 className="itemsCount">You have {cartItemsCount()} items in your cart.</h5>
                                <hr/>
                                {cart && cart.map((item,index)=>(
                                    <CartItems key={index} props={item} />
                                ))}
                                {cart.length!=0&&
                                <div className="checkoutButtonContainer">
                                    <Link to='/checkout' className="checkoutButton" onClick={handleCartClose}>
                                        Go To Checkout
                                    </Link>
                                </div>
                                }
                            </div>
                        </div>
                    </Box>
                </Modal>


            <Modal open={pokeOpen} onClose={handlePokeClose}>
                <Box >
                    <ul className="mobile-navbar-list" onClick={handlePokeClose}>
                        <li> <NavLink className="hover" to="/"> HOME </NavLink> </li>
                        <li> <NavLink className="hover" to="/Products"> PRODUCTS </NavLink> </li>
                        <li> <NavLink className="hover" to="/Business"> BUSINESS </NavLink> </li>
                        <li> <NavLink className="hover" to="/About"> ABOUT </NavLink> </li>
                        <li> <NavLink className="loginButton" to="/login"> LOGIN </NavLink> </li>
                    </ul>
                </Box>
            </Modal>
        </div>
    )
}

export default MobileNavbar