import { CompanyEmployeesContext } from "../context/CompanyEmployeesContext";
import { useContext } from "react";

export const useCompanyEmployeesContext = () =>{
    const context = useContext(CompanyEmployeesContext)
    
    if(!context) {
        throw Error('CompanyEmployeesContext must be used inside an CompanyEmployeesContextProvider')
      }
    return context
}