import { useEffect, useState } from "react";
import { Outlet, useLoaderData, useParams } from "react-router-dom";
import DashboardNavbar from "../components/DashboardNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/font-logo.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import logo2 from "../assets/images/cardtap-white.png"
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from "react-router-dom";
import CorporateNavbar from "../components/Corporates/CorporateNavbar";
import { useUserContext } from "../hooks/useUserContext";
import useLogout from "../hooks/useLogout";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { useCompanyEmployeesContext } from "../hooks/useCompanyEmployeesContext";
export default function RootLayout() {
    const [companyEmployees,setCompanyEmployees] = useState(null);
    const { companyName } = useParams()
    const {employees,dispatch} = useCompanyEmployeesContext();
    const {user} = useUserContext()
    const [pokeOpen, setPokeOpen] = useState(false);
    const handlePokeOpen = () => setPokeOpen(true);
    const handlePokeClose = () => setPokeOpen(false);
    const { logout } = useLogout();


    const handleClick = () => {
        logout()
    }

    useEffect(() => {
        if(location.pathname==='/CorporateDashboard/'+companyName) setDefaultLoadingDashboard(true)
        const getEmployees = async () =>{
            try{
                const res = await axios.get(`/users/companyProfiles/${companyName}`, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': `Bearer ${user.token}`
                    },
                })
                setCompanyEmployees(res.data)
                dispatch({type:"SET_EMPLOYEES",payload:res.data})
            }
            catch(err){
                console.log(err.response.data.error.message)
                if(err.response.data.error.message ==='jwt expired'){
                    logout()
                }
                console.log("No internet connection")
            }
        }
        getEmployees()
    }, [])
    const [navbar, setNavbar] = useState(false);
    const handleNavOpen = () => {
        setNavbar(!navbar);
    }

    //Handle Active Tab
    const location = useLocation();
    const [defaultLoadingDashboard,setDefaultLoadingDashboard] = useState(false)
    useEffect(()=>{
    })
    const handleLinkClick =()=>{
        setDefaultLoadingDashboard(false)
    }
    return (
        <div className="background">
            <div className="container">
                <div className="dashboardContainer">
                    <div className="navBurgerHolder"> 
                        <Button style={{ 'color': 'black' }} className='taskOpenBtn' onClick={handlePokeOpen}>
                            <span className="navBurger">  <FontAwesomeIcon icon={faBars} size="2x" /> </span>
                        </Button>
                        <div className="dashboardImgHolder">
                            <img src={logo} />
                        </div>
                    </div>

                    <Modal open={pokeOpen} onClose={handlePokeClose}>
                        <Box >
                            <div style={{ 'fontFamily': 'poppins' }} className="dashboardNavbar">
                                <div className="dashboardNavbar-img"> <img src={logo2} /> </div>
                                <ul className="dashboardNavbar-list" onClick={handlePokeClose}>
                                    <li><NavLink className={defaultLoadingDashboard?"link-dashboardNavbar active":"link-dashboardNavbar"} to={'/CorporateDashboard/' + companyName + '/'}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faChartSimple} /></span><span className="text-dashboardNavbar">Dashboard</span></NavLink></li>
                                    <li><NavLink className="link-dashboardNavbar" to={'/CorporateDashboard/' + companyName + '/analytics'} onClick={handleLinkClick}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faUsers} /></span ><span className="text-dashboardNavbar">Analytics</span> </NavLink></li>
                                        {/* <li><NavLink className="link-dashboardNavbar" to={'/CorporateDashboard/' + companyName + '/employees'} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faShareNodes} /></span><span className="text-dashboardNavbar">Employees</span></NavLink></li> */}
                                    <li><NavLink className="link-dashboardNavbar" to={'/CorporateDashboard/' + companyName + '/edit'} onClick={handleLinkClick}><span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faLink} /></span><span className="text-dashboardNavbar">Edit</span></NavLink></li>
                                </ul>
                                <button onClick={handleClick} className="logout-btn"> Logout </button>
                            </div>
                        </Box>
                    </Modal>

                    <div className="desktopDashboardNavbar">
                        <CorporateNavbar company={companyName} />
                    </div>
                    {companyEmployees ? <div className="dashboardContent">
                        <Outlet context={companyEmployees} />
                    </div> : <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>}
                </div>
            </div>
        </div>
    )
}

// export const CorporateProfilesLoader = async ({ params }) => {
//     const { companyName } = params
//     const res = await fetch(process.env.REACT_APP_URL + `/users/companyProfiles/${companyName}`)
//     return res;
// }