// import section6Img from "../assets/images/section6-img.png"
import { Link } from "react-router-dom";
import { useCartContext } from "../hooks/useCartContext";
import { useEffect,useState } from "react";
import swal from 'sweetalert';

// import Ali from '../assets/images/Untitled_design.gif'
// import Stack from '@mui/material/Stack';
const ProductHolder = ({props}) => {

    const { cart, dispatch } = useCartContext();
    useEffect(()=>{
        localStorage.setItem('cart',JSON.stringify(cart));
    },[cart])
    const [addClicked,setAddClicked] = useState(false)

    const handleAddToCart = (data) => {
        let arr = cart;
        // let priceAfterSale;
        let itemIndex = arr.findIndex(x=> x._id == data._id)

        // if (data.sale!=0) {
        //     priceAfterSale = calculatePriceAfterSale()
        //     let priceString = priceAfterSale.toFixed(2).toString() + " LE"
        //     priceAfterSale = priceString
        // }
        if(itemIndex>-1){
            console.log(arr[itemIndex])
            arr[itemIndex].quantity+=1
            // if (data.sale!=0) arr[itemIndex].price = priceAfterSale
            dispatch({type:'UPDATE_ITEM',payload:arr});
        }
        else{
            data.quantity=1
            // if (data.sale!=0) data.price = priceAfterSale
            dispatch({type:'ADD_ITEM',payload:data}); 
        }
        swal({
            title: "Added to cart successfuly",
            icon: "success",
            button: "Continue shopping",
          });
        setTimeout(() => {
            setAddClicked(false)
        }, 1000);    
    }
    const calculatePriceAfterSale = () => {
        let price = parseInt(props.price) + 1
        let sale = props.sale/100
        return price - (price*sale) - 1 
    }
    return (
        <div style={{'fontFamily':'poppins'}} className="productCard-productsPage">
             <Link to={{pathname:`/products/${props.URL_Name}`}} style={{position:'relative',display:'flex',alignItems:'center',flexDirection:'column'}}>
                <div className="productImgHolder">
                    <img src={props.imgLink} alt={props.name} />
                    {props.sale!=0&&<p className="salePercentage">{props.sale}% OFF</p>}
                </div>
            
            <p className="productName"> {props.name} </p>

            { props.sale==0?
                <div className="priceAndSaleContainer" style={{justifyContent:'center'}}>
                    <p className="productPrice" > {props.price}</p>
                </div>
                :
                <div className="priceAndSaleContainer" style={props.sale==0?{justifyContent:'center'}:{}}>
                    <p className="productPrice custom-underline-productHolder" style={{opacity:'0.5'}}> {props.price}</p>
                    <p className="productPrice" style={{color:'red'}}> {calculatePriceAfterSale().toFixed(2)} LE</p>
                </div>
            }
            </Link>
            {/* <div className="priceAndSaleContainer">
            <p className="productPrice" style={props.sale==0?{}:{opacity:'0.7'}}> {props.price}</p>
            <p className="productPrice" style={props.sale==0?{display:'none'}:{color:'red'}}> {props.price}</p>
            </div> */}
            { props._id != "651b3dfa2b4af7127cb8ef4c"? 
                <Link style={{'display':(props.name === 'Cardtap NFC Business Metal Card & Tag Offer' || props.name === 'Cardtap NFC Business Card & Tag Bundle Offer')?'none':'auto'}} className={addClicked ? "button-ProductsPage-clicked" : "button-ProductsPage"} disabled={addClicked} onClick={(e) => {setAddClicked(true);handleAddToCart(props)}}> Add to cart </Link>
                :
                <Link className="button-ProductsPage" to={`/products/${props.URL_Name}`}> View Product </Link>
            }
        </div>
    );
}

export default ProductHolder