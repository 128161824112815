import kimo from '../assets/images/Business.jpg';
import { Helmet } from 'react-helmet-async';
import vid from '../assets/images/vid.mp4';
import { useEffect, useState } from "react";
import swal from 'sweetalert';

const BusinessPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);  
    }, []);
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [cName, setCname] = useState("")
    const handleGetInTouch = async (event) => {
        event.preventDefault();
        console.log("Fixed")
        const formData = new FormData();

        formData.append('Email', email);
        formData.append('Phone', phone);
        formData.append('Name', name);
        formData.append('CompanyName', cName);
        
        try{
            const res = await fetch('/users/getInTouch',
                {
                    method:'POST',
                    body:formData
                }
            )
            if(res.ok){
                swal({
                    title: "Request submitted successfuly, We will contact you very soon.",
                    icon: "success",
                });
            }
            else{
                swal({
                    title: "An error has occured, please try again later.",
                    icon: "error",
                });
            }
            setCname("")
            setEmail("")
            setName("")
            setPhone("")
        }
        catch(err){
            console.log(err)
        }
    }
    return (
        <div>
            <Helmet>
                <title>Cardtap | Unlock Efficiency: NFC Cards for Business</title>
                <meta name="description" content="Elevate your business connections with Cardtap's elegant NFC cards. Streamline networking today. Best Prices for businesses." />
            </Helmet>
            <div style={{ 'fontFamily': 'poppins' }} className="businessContainer">
                <div className="headerContainer">
                    <h1>Why print hundreds of papers when you can just have a life-time NFC card.</h1>
                </div>
                <div className="bus-call-but-mobile">
                    <a href='#connect-form' className="headerButton"> Get in touch with us</a>
                </div>
                <div className="bus-call-but">
                    <a href='#connect' className="headerButton"> Get in touch with us</a>
                </div>
                <div className="upper-img-holder">
                    <img src={kimo} alt="A man holding Cardtap's digital NFC gold metal card" />
                </div>
                <div className="dashboardImgContainer">
                    <h1 className='dashboardTitle'>Manageable Dashboard For Corporates</h1>
                    <video className='desktop-video' autoPlay style={{ 'width': '100%', 'height': 'auto', 'borderRadius': '10px' }} muted loop>
                        <source src={vid} type="video/mp4" />
                    </video>
                    <div className='mobile-gif'>
                    </div>
                </div>
                <div className="getInTouchContainer">
                    <div className="getInTouchLeft">
                        <h3>Get in touch</h3>
                        <p>Drop us an email, and we will get back to you </p>
                    </div>
                    <div className="getInTouchRight">
                        <form id='connect' className="getInTouchForm" onSubmit={handleGetInTouch}>
                            <div className='getInTouchFormInputHolder'>
                                <label>
                                    Full Name
                                </label>
                                <input type='text' value={name} onChange={e => setName(e.target.value)} required />
                            </div>
                            <div className='getInTouchFormInputHolder'>
                                <label>
                                    Business Email
                                </label>
                                <input type='email' value={email} onChange={e => setEmail(e.target.value)} required />
                            </div>
                            <div className='getInTouchFormInputHolder'>
                                <label>
                                    Mobile Number
                                </label>
                                <input type='tel' pattern="[0-9+]{5,15}" value={phone} onChange={e => setPhone(e.target.value)} title="Please enter a valid phone number" required />
                            </div>
                            <div className='getInTouchFormInputHolder'>
                                <label>
                                    Company Name (Optional)
                                </label>
                                <input type='text' value={cName} onChange={e => setCname(e.target.value)} />
                            </div>
                            <button type='submit'> Submit Now </button>
                        </form>
                    </div>
                </div>
                <div className="getInTouchContainer-mobile">
                    <div className="getInTouchContainer-mobile-img">
                        <h1>Get in touch</h1>
                        <p>Drop us an email,and we will get back to you </p>
                    </div>
                    <form id='connect-form' className="getInTouchForm" onSubmit={handleGetInTouch}>
                        <div className='getInTouchFormInputHolder'>
                            <label>
                                Full Name
                            </label>
                            <input type='text' value={name} onChange={e => setName(e.target.value)} required />
                        </div>
                        <div className='getInTouchFormInputHolder'>
                            <label>
                                Business Email
                            </label>
                            <input type='email' value={email} onChange={e => setEmail(e.target.value)} required />
                        </div>
                        <div className='getInTouchFormInputHolder'>
                            <label>
                                Mobile Number
                            </label>
                            <input type='tel' pattern="[0-9+]{5,15}" value={phone} onChange={e => setPhone(e.target.value)} title="Please enter a valid phone number" required />
                        </div>
                        <div className='getInTouchFormInputHolder'>
                            <label>
                                Company Name (Optional)
                            </label>
                            <input type='text' value={cName} onChange={e => setCname(e.target.value)} />
                        </div>
                        <button type='submit'> Submit Now </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BusinessPage