import logo from '../../assets/images/font-logo.png';
import Platform from '../Platform';
import BusinessLinks from '../BusinessLinks';
import { useState } from 'react';

const CorporateMocket = ({ profile }) => {
    let c;
    const [showMore, setShowMore] = useState(false);
    console.log(profile.profiles[profile.activeProfile].colorTheme)
    if (profile.profiles[profile.activeProfile].colorTheme === '#d9d9d9') {
        c = '217, 217, 217'
    }
    else {
        c = profile.profiles[profile.activeProfile].colorTheme
    }
    console.log(c);
    return (
        <div className="profileContainer">
            <div className="mocketLogo">
                <img src={logo} />
            </div>
            <div className="mocketCover">
                <img src={profile.profiles[profile.activeProfile].image2} />
            </div>
            <div className="imgHolder">
                <div className="mocketImage">
                    <img src={profile.profiles[profile.activeProfile].image1} />
                </div>
            </div>
            <div className='nameHolder'>
                <h1> {profile.name} </h1>
                <h4> {profile.profiles[profile.activeProfile].job}</h4>
            </div>
            <div className="dashcontactButtons">
                <a style={{"pointerEvents":"none", 'background': `rgba(${c},1)`, 'color': 'black', 'width': '105%', 'fontWeight': '800' }} href={profile.profiles[profile.activeProfile].contact_link}> SAVE CONTACT </a>
                <a style={{"pointerEvents":"none"}} href=''> EXCHANGE INFO </a>
            </div>
            {profile.profiles[profile.activeProfile].bio && <div className="bio">
                <div className='bioTitle'>Bio</div>
                <p style={{ 'background': `rgba(${profile.profiles[profile.activeProfile].colorTheme},.4)` }}> {profile.profiles[profile.activeProfile].bio}</p>
                
            </div>}
            <div className="platformsHolder">
                {profile.profiles[profile.activeProfile].socialMedia && profile.profiles[profile.activeProfile].socialMedia.map((app, index) => (
                    <Platform corp={false} key={index} props={app} color={profile.profiles[profile.activeProfile].colorTheme} />
                ))}
            </div>
            <div className="businessLinkHolder">
                {profile.profiles[profile.activeProfile].businessInfo && profile.profiles[profile.activeProfile].businessInfo.map((app, index) => (
                    <BusinessLinks corp={false} key={index} props={app} color={profile.profiles[profile.activeProfile].colorTheme} idx={index} />
                ))}
            </div>
        </div>
    );
}
export default CorporateMocket;