import MobileMocket from "./MobileMocket";
import avatar from "../assets/images/Ellipse 18.png";
import avatar2 from "../assets/images/Ellipse 17.png";
import avatar3 from "../assets/images/Ellipse 19.png";
import avatar4 from "../assets/images/Ellipse 20.png";
import avatar5 from "../assets/images/Ellipse 21.png";
import avatar6 from "../assets/images/Ellipse 22.png";
import avatar7 from "../assets/images/Ellipse 23.png";
import avatar8 from "../assets/images/Ellipse 24.png";
import avatar9 from "../assets/images/Ellipse 25.png";
import avatar10 from "../assets/images/Ellipse 26.png";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useUserContext } from "../hooks/useUserContext";
import { useProfileContext } from "../hooks/useProfileContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { useOutletContext } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
const ProfileView = () => {
    const { user } = useUserContext();
    const [handleTutorialClick] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false)
    const avatars = [avatar, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10]
    const covers = ["https://res.cloudinary.com/cardtap/image/upload/v1691264143/black-card_lvqaqc.png",
        "https://res.cloudinary.com/cardtap/image/upload/v1691264143/white-card_ifqc8f.png"]
    const navigate = useNavigate();
    const { idx } = useParams();
    const { profile, dispatch } = useProfileContext();
    const [theme, setTheme] = useState();
    const [cover, setCover] = useState();
    const [image, setImage] = useState();
    const [profileName, setProfileName] = useState(profile.profiles[idx].profileName || "");
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState("")
    useEffect(() => {
        if (handleTutorialClick[0]) {
            console.log('Start Tutorial button clicked in Outlet component');
            const driverObj1 = driver({
                showProgress: true,  // Because everyone loves progress bars!
                steps: [
                    {
                        element: '.profileContainer',
                        popover: {
                            title: 'Your Profile',
                            description: "Here are your profile overview. You can delete any social media application or business information by clicking on the minus button."
                        }
                    },
                    {
                        element: '.avatars',
                        popover: {
                            title: 'Profile Color Theme',
                            description: 'Here is your profile color theme selection. Every avatar shows a different persona about you, different profile colors. Choose the best describes yours.'
                        }
                    },
                    {
                        element: '.cover',
                        popover: {
                            title: 'Profile Cover',
                            description: 'Here you can choose your profile cover, either one of our cards (White or Black Edition) or upload yours.'
                        }
                    },
                    {
                        element: '.userImage',
                        popover: {
                            title: 'Profile Image',
                            description: "Here you can upload your image (ps. Better be a portrait one's."
                        }
                    },
                    {
                        element: '.profileTextInput',
                        popover: {
                            title: 'Profile Name',
                            description: "Here is your profile name, for differentiating between your profiles."
                        }
                    }
                ]
            });
            driverObj1.drive();
            console.log("here")

            // Call your function or perform any other actions here
        }
    }, [theme, cover, image, dispatch,handleTutorialClick])

    let avatarIdx;
    switch (profile.profiles[idx].colorTheme) {
        case "255,230,199":
            avatarIdx = 0
            break;
        case "255,84,40":
            avatarIdx = 1
            break;
        case "254,173,133":
            avatarIdx = 2
            break;
        case "248,80,116":
            avatarIdx = 3
            break;
        case "173,182,215":
            avatarIdx = 4
            break;
        case "242,158,176":
            avatarIdx = 5
            break;
        case "119,184,160":
            avatarIdx = 6
            break;
        case "204,206,197":
            avatarIdx = 7
            break;
        case "227,181,71":
            avatarIdx = 8
            break;
        case "252,210,92":
            avatarIdx = 9
            break;
    }

    let coverIdx;
    switch (profile.profiles[idx].image2) {
        case "https://res.cloudinary.com/cardtap/image/upload/v1691264143/black-card_lvqaqc.png":
            coverIdx = 0
            break;
        case "https://res.cloudinary.com/cardtap/image/upload/v1691264143/white-card_ifqc8f.png":
            coverIdx = 1
            break;
    }

    const handleAvatarTheme = async (avatarIndex) => {
        let color;
        setIsLoading(true)
        switch (avatarIndex) {
            case 0:
                color = "255,230,199"
                avatarIndex = 0
                break;
            case 1:
                color = "255,84,40"
                avatarIndex = 1
                break;
            case 2:
                color = "254,173,133"
                avatarIndex = 2
                break;
            case 3:
                color = "248,80,116"
                avatarIndex = 3
                break;
            case 4:
                color = "173,182,215"
                avatarIndex = 4
                break;
            case 5:
                color = "242,158,176"
                avatarIndex = 5
                break;
            case 6:
                color = "119,184,160"
                avatarIndex = 6
                break;
            case 7:
                color = "204,206,197"
                avatarIndex = 7
                break;
            case 8:
                color = "227,181,71"
                avatarIndex = 8
                break;
            case 9:
                color = "252,210,92"
                avatarIndex = 9
                break;
        }
        let profilesHolder = profile;
        setTheme(color);
        profilesHolder.profiles[idx].colorTheme = color;
        dispatch({ type: "UPDATE_PROFILES", payload: profilesHolder })
        const formData = new FormData();
        formData.append('Theme', color);
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', idx);
        try {
            await axios.post(`/users/themeUpdate`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    setIsLoading(false)
                })
        }
        catch (err) {
            console.log("No internet connection")
        }
    }

    const handleCover = async (coverIndex) => {
        setIsLoading(true)
        let newCover;
        switch (coverIndex) {
            case 0:
                newCover = "https://res.cloudinary.com/cardtap/image/upload/v1691264143/black-card_lvqaqc.png"

                break;
            case 1:
                newCover = "https://res.cloudinary.com/cardtap/image/upload/v1691264143/white-card_ifqc8f.png"
                break;
        }
        const formData = new FormData();
        formData.append('Link', newCover);
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', idx);

        try {
            await axios.post(`/users/coverUpdate`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    'Content-Type' : 'multipart/form-data',
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    let c = 1
                    setCover(newCover)
                    let profilesHolder = profile;
                    console.log(coverIndex)
                    profilesHolder.profiles[idx].image2 = newCover;
                    dispatch({ type: "UPDATE_PROFILES", payload: profilesHolder })
                })
        }
        catch (err) {
            console.log("No internet connection")
        }
        setIsLoading(false)

    }

    const handleChangeImage = async (e) => {
        console.log(e.target.files[0]);
        const maxAllowedSize = 5 * 1024 * 1024;
        if (e.target.files[0] !== undefined && e.target.files[0].size <= maxAllowedSize) {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('img', e.target.files[0]);
            formData.append('Email', profile.email);
            formData.append('ProfileIdx', idx);

            try {
                await axios.post(`/users/imageUpdate`, formData, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        'Content-Type' : 'multipart/form-data',
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': `Bearer ${user.token}`
                    },
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(json)
                        let profilesHolder = profile;
                        setImage(json.secure_url)
                        profilesHolder.profiles[idx].image1 = json.secure_url;
                        dispatch({ type: "UPDATE_PROFILES", payload: profilesHolder })
                        setIsLoading(false)
                    })

            }
            catch (err) {
                console.log("No internet connection")
            }
        }
        else {
            setAlert("error")
            setMessage("Image Exceeded Limit 5MB")
        }
    }

    const handleChangeCover = async (e) => {
        const maxAllowedSize = 5 * 1024 * 1024;
        if (e.target.files[0] !== undefined && e.target.files[0].size <= maxAllowedSize) {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('cover', e.target.files[0]);
            formData.append('Email', profile.email);
            formData.append('ProfileIdx', idx);

            try {
                await axios.post(`/users/coverUpdate`, formData, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        'Content-Type' : 'multipart/form-data',
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': `Bearer ${user.token}`
                    },
                })
                    .then(async (res) => {
                        const json = res.data
                        console.log(json)
                        let profilesHolder = profile;
                        setImage(json.secure_url)
                        profilesHolder.profiles[idx].image2 = json.secure_url;
                        dispatch({ type: "UPDATE_PROFILES", payload: profilesHolder })
                        setIsLoading(false)
                        setMessage("")
                    })
            }
            catch (err) {
                console.log("No internet connection")
            }
        }
        else {
            setAlert("error")
            setMessage("Image Exceeded Limit 5MB")
        }
    }

    const handleSave = async () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('ProfileName', profileName);
        formData.append('ProfileIdx', idx);

        try {
            await axios.post(`/users/profileSave`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    let profilesHolder = profile;
                    profilesHolder.profiles[idx].profileName = profileName;
                    dispatch({ type: "UPDATE_PROFILES", payload: profilesHolder })
                    setIsLoading(false)
                })
        }
        catch (err) {
            console.log("No internet connection")
        }
    }

    return (
        <div className="profileViewContainer">
            <div className="profileView-leftPart">
                <h4 style={{ 'fontFamily': 'poppins', 'textAlign': 'center', 'background': '#d9d9d9', 'padding': '10px', 'borderRadius': '16px' }}>{profile.profiles[idx].profileName || "Add Name"}</h4>
                <MobileMocket profile={profile.profiles[idx]} />
            </div>
            {isLoading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <div className="profileView-rightPart">
                <div className="avatars">
                    {avatars && avatars.map((i, index) => (
                        <img style={{ 'borderRadius': '50%', 'cursor': 'pointer', 'border': (index === avatarIdx) ? '2px solid black' : 'none' }} onClick={(e) => handleAvatarTheme(index)} key={index} src={i} />
                    ))}
                </div>
                {message && <Alert style={{ 'margin': '10px' }} severity={alert}>{message}</Alert>}
                <div className="cover">
                    {covers && covers.map((i, index) => (
                        <img style={{ 'borderRadius': '16px', 'cursor': 'pointer', 'border': (index === coverIdx) ? '2px solid red' : 'none' }} onClick={(e) => handleCover(index)} key={index} src={i} />
                    ))}
                    <div style={{ 'textAlign': 'center' }} className='file2 file--upload'>
                        <img src={profile.profiles[idx].image2} />
                        <input id='input-file' type='file' name="image" onChange={(e) => { handleChangeCover(e) }} />
                    </div>
                </div>

                <div className="userImage">
                    <h2>
                        CHANGE PHOTO
                    </h2>
                    <div className='file3'>
                        <img src={profile.profiles[idx].image1} />
                        <input id='input-file' type='file' name="image" onChange={(e) => { handleChangeImage(e) }} />
                    </div>
                </div>

                <div className="profileButtons">
                    <input value={profileName} onChange={(e) => setProfileName(e.target.value)} placeholder="Profile Name" type="text" className="profileTextInput" />
                    <div className="buttonsHolder">
                        <button onClick={handleSave}> SAVE</button>
                        <button onClick={(e) => navigate(-1)} > BACK</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProfileView;