import logo from "../../assets/images/cardtap-white.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useParams } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
// import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
const CorporateNavbar = ({company}) => {
    const {logout} = useLogout();
    // const [tabClicked,setTabClicked] = useState("");
    const location = useLocation();
    const [defaultLoadingDashboard,setDefaultLoadingDashboard] = useState(false)
    const handleClick = () =>{
        logout()
    }
    useEffect(()=>{
        if(location.pathname==='/CorporateDashboard/'+company) setDefaultLoadingDashboard(true)
    })
    const handleLinkClick =()=>{
        setDefaultLoadingDashboard(false)
    }
    return (
        <div style={{'fontFamily':'poppins'}} className="dashboardNavbar">
            <div className="dashboardNavbar-img"> <img src={logo} /> </div>
                <ul className="dashboardNavbar-list">
                    <li> <NavLink to={'/CorporateDashboard/'+company+'/'}  className={defaultLoadingDashboard?"link-dashboardNavbar active":"link-dashboardNavbar"}> <span className="icons-dashboardNavbar"><FontAwesomeIcon icon={faChartSimple} /></span><span className="text-dashboardNavbar">Dashboard</span></NavLink></li>
                    <li> <NavLink to={'/CorporateDashboard/'+company+'/analytics'}  className="link-dashboardNavbar" onClick={handleLinkClick}> <span className="icons-dashboardNavbar" ><FontAwesomeIcon icon={faUsers} /></span ><span className="text-dashboardNavbar">Analytics</span> </NavLink></li>
                    <li> <NavLink  to={'/CorporateDashboard/'+company+'/edit'}  className="link-dashboardNavbar" onClick={handleLinkClick}> <span className="icons-dashboardNavbar" ><FontAwesomeIcon icon={faLink} /></span><span className="text-dashboardNavbar">Edit</span></NavLink></li>
                </ul>
            <button onClick={handleClick} className="logout-btn logout-corp"> Logout </button>
        </div>
    );
}
export default CorporateNavbar