import ProductsPage from "../pages/ProductsPage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus,faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useCartContext } from "../hooks/useCartContext";
import Paper from '@mui/material/Paper';
const CartItems = ({ props }) => {
    const {cart , dispatch} = useCartContext();

    const handleMinus = (data) =>{
        if(data.quantity > 1){
            let arr = cart;
            arr.find(x=> x._id === data._id).quantity-=1;
            dispatch({type:'UPDATE_ITEM',payload:arr});
        }
    }
    const handlePlus = (data) =>{
        let arr = cart;
        arr.find(x=> x._id === data._id).quantity+=1;
        dispatch({type:'UPDATE_ITEM',payload:arr});
        
    }
    const handleRemoveItem = (data) =>{
        dispatch({type:'DELETE_ITEM',payload:data});
    }

    const calculatePriceAfterSale = () => {
        let price = parseInt(props.price) + 1
        let sale = props.sale/100
        return price - (price*sale) - 1 
    }

    return (
        <div className="itemHolder">
            {/* <div className="itemImg">
                <img src={props.imgLink} />
            </div> */}
            {/* <div className="itemInfo"> */}
                {/* <span>
                    {props.name}
                </span> */}
                {/* <div className="actionHolder"> */}
                    {/* </div><div className="itemAction"> */}
                        {/* <FontAwesomeIcon onClick={()=> handleMinus(props)} icon={faMinus} />
                        <span> {props.quantity} </span>
                        <FontAwesomeIcon onClick={()=> handlePlus(props)} icon={faPlus} />
                    </div>
                    <span onClick={()=> handleRemoveItem(props)} className="removeItemButton"> Remove </span> */}
                <Paper className="cartItems" sx={{borderRadius:'20px'}}> 
                    <img className="itemImage" src={props.otherImgsLinks[0]}/>
                    <div className="itemDetails">
                        <p className="itemTitle">{props.name}</p>
                        {/* <p className="itemCardType">Standard White</p> */}
                    </div>
                    <div className="cart-func-holder">
                        <div className="itemQuantity">
                            {(props.name !== 'Cardtap NFC Business Metal Card & Tag Offer' && props.name !== 'Cardtap NFC Business Card & Tag Bundle Offer')&&<div className="quantityCounter">
                                <FontAwesomeIcon  icon={faMinus} onClick={()=> handleMinus(props)}/>
                                <span style={{marginBottom:'5%'}}> {props.quantity} </span>
                                <FontAwesomeIcon  icon={faPlus} onClick={()=> handlePlus(props)}/>
                            </div>}
                        </div>
                        <div className="itemPrice">
                            { props.sale!=0?
                            <span>
                                {(props.quantity*calculatePriceAfterSale()).toFixed(2)} LE
                            </span>
                            :
                            <span>
                                {(props.quantity*parseInt(props.price)).toFixed(2)} LE
                            </span>
                            }
                        </div>
                        
                        <div className="itemDeleteButton" >
                            <FontAwesomeIcon icon={faTrashCan} onClick={()=> handleRemoveItem(props)} style={{cursor:'pointer'}}/>
                        </div>
                        
                    </div>
                </Paper>
                {/* </div> */}
            {/* </div> */}
        </div>
    );
}

export default CartItems