import person from "../assets/images/person.png"
import pIcon from "../assets/images/pIcon.png"
import eIcon from "../assets/images/eIcon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import VCard from 'vcard-creator'
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useUserContext } from "../hooks/useUserContext";
import { useProfileContext } from "../hooks/useProfileContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'; 
import { useState } from "react";
const ContactCard = ({contact,id}) =>{
    const {user} = useUserContext()
    const [isLoading, setIsLoading] = useState(false)
    const {profile,dispatch} = useProfileContext()

    const handleClick = () =>{
        setIsLoading(true)
        const myVCard = new VCard()

        myVCard
        .addName(contact.Name)
        .addEmail(contact.Email)
        .addPhoneNumber(contact.Number, 'PREF;WORK')

        const file = new Blob([myVCard.toString()], { type: 'text/vcard;charset=utf-8' });
        saveAs(file, `${contact.Name}.vcf`);
        setIsLoading(false)
        
    } 
    const handleDeleteContact = async () =>{
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('Idx', id);
        formData.append('Name', contact.Name);
        const response = await axios.post( `/users/clientsContactsDelete`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
                dispatch({ type: "UPDATE_PROFILES", payload: json })
                setIsLoading(false)

            })
            .catch((err)=>{
                console.log("No internet Connection")
            })
    }
    return(
        <div className="myContactsCardContainer">
            {isLoading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <span className="deleteContact" onClick={handleDeleteContact} > <FontAwesomeIcon icon={faXmark} size="xl" style={{color: "#000000",}} /> </span>
            <div className="contactCardpart1">
                <img src={person} />
                <p> {contact.Name} </p>
            </div>
            <div className="contactCardpart2">
                <div className="phone-email"> <img src={pIcon} /> <span> {contact.Number} </span> </div>
                <div className="phone-email"> <img src={eIcon} /> <span> {contact.Email} </span> </div>
                <div className="phone-email"> <FontAwesomeIcon icon={faNoteSticky} size="xl" style={{color: "#ae796a",padding: '10px 11px','background':'#F2F2F2','borderRadius':'12px'}} /> <span>{contact.Notes}</span></div>
            </div>
            <button className="save-ex" onClick={handleClick}> Save To Phone </button>
        </div>
    );
}

export default ContactCard