import MobileMocket from "./MobileMocket";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../hooks/useUserContext"
import { useProfileContext } from "../hooks/useProfileContext";
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useOutletContext } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
const Profiles = () => {
    const navigate = useNavigate();
    const [handleTutorialClick] = useOutletContext();
    const { profile, dispatch } = useProfileContext();
    const { user } = useUserContext()
    const [active, setActive] = useState();
    const { isUser } = useParams();
    console.log(isUser)
    const [removeIdx, setRemoveIdx] = useState();
    const [isLoading, setIsLoading] = useState(false)

    let profiles = profile.profiles

    useEffect(() => {
        if (handleTutorialClick[0]) {
            console.log('Start Tutorial button clicked in Outlet component');
            const driverObj1 = driver({
                showProgress: true,  // Because everyone loves progress bars!
                steps: [
                    {
                        element: '.dashboardContent',
                        popover: {
                            title: 'Profiles page',
                            description: 'Here you will have all of cardtap profiles.'
                        }
                    },
                    {
                        element: '.profileSettingsHolder',
                        popover: {
                            title: 'Your Profile',
                            description: "Here are your profiles, you can click on it to open it and set it's visuals."
                        }
                    },
                    {
                        element: '.settingsButtons',
                        popover: {
                            title: 'Active Button',
                            description: 'Here is your profile active button, used to activate this profile. When you tap your card the activated profile will be shown to your customer.'
                        }
                    },
                    {
                        element: '.removeButton-tutorial',
                        popover: {
                            title: 'Remove Button',
                            description: 'Here is your profile remove button, you can click it to remove your profile. (ps. You cant remove active profile)'
                        }
                    },
                    {
                        element: '.addProfileButton',
                        popover: {
                            title: 'Profile Add Button',
                            description: 'Here you can add new profile by clicking on it.'
                        }
                    }
                ]
            });
            driverObj1.drive();
            console.log("here")

            // Call your function or perform any other actions here
        }
    }, [active, removeIdx, handleTutorialClick,profile,dispatch])

    const handleAddProfile = async () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        try {
            await axios.post('/users/addNewProfile', formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    dispatch({ type: 'UPDATE_PROFILES', payload: json })
                    console.log(json);
                    navigate(`profile/${json.profiles.length - 1}`)
                    setIsLoading(false)
                })
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleActive = async (ProfileIndex) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', ProfileIndex);
        console.log(parseInt(ProfileIndex))
        try {
            await axios.post('/users/setActiveProfile', formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    // let profilesHolder = profile;
                    // profilesHolder.activeProfile = parseInt(ProfileIndex);
                    setActive(parseInt(ProfileIndex));
                    dispatch({ type: "UPDATE_PROFILES", payload: json })
                    setIsLoading(false)
                })

        }
        catch (err) {
            console.log(err);
        }
    }

    const handleRemove = async (ProfileIndex) => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', ProfileIndex);

        try {
            await axios.post('/users/removeProfile', formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    setRemoveIdx(parseInt(ProfileIndex))
                    dispatch({ type: "UPDATE_PROFILES", payload: json })
                    setIsLoading(false)
                })


        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="profilesContainer">
            {isLoading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            {profiles && profiles.map((i, idx) => (
                <div key={idx} className="profileSettingsHolder" >
                    <h4 style={{ 'fontFamily': 'poppins', 'textAlign': 'center', 'background': '#d9d9d9', 'padding': '10px', 'borderRadius': '16px' }}>{i.profileName || "Add Name"}</h4>
                    <div className="pHolder" onClick={(e) => navigate(`profile/${idx}`)} style={{ 'overflow': 'hidden' }}>
                        <MobileMocket profile={profiles[idx]} />
                    </div>
                    <div className="settingsButtons">
                        <button style={{ 'background': (idx === profile.activeProfile) ? '#C9F4AA' : '#D9D9D9' }} onClick={(e) => handleActive(idx)}>ACTIVE</button>
                        <button className="removeButton-tutorial" disabled={(idx === profile.activeProfile)} onClick={(e) => handleRemove(idx)}>REMOVE</button>
                    </div>
                </div>
            ))}
            {
                (profile.profiles.length <= 2  && (isUser === 'Main' || isUser ==="AAST")) &&
                <div style={{'cursor':'pointer'}} className="addProfileButton" onClick={handleAddProfile}>
                    <p>ADD PROFILE</p>
                </div>
            }
        </div>
    );
}
export default Profiles;