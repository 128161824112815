import logo from '../assets/images/font-logo.png'
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faEnvelope,faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook , faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import swal from 'sweetalert';
import {useState} from "react";

const Navbar = ({ style }) => {

    const [email,setEmail] = useState("")

    const handleClick = async (e) =>{
        e.preventDefault()
        const formData = new FormData();
        formData.append('Email', email);
        try{
            const res = await fetch('/users/newsLetter',
                {
                    method:'POST',
                    body:formData
                }
            )
            const json = await res.json();
            setEmail("")
            swal ( "Subscription successful" ,  "You have subscriped to our newsletter, you will now recieve our latest news, offers, and much more." ,  "success" )
        }
        catch(err){
            console.log(err)
            swal ( "An error has occured" ,  "Please try again later." ,  "error" )
        }
    }



    return (
        <div className="footer-holder" style={{ 'top': style }}>
            <div className="logo-holder">
                <img className='footer-logo' src={logo} alt="Cardtap's logo"/>
            </div>
            {/* <p style={{ 'fontFamily': 'poppins' }} className='footer-text'>We are easing the burden of exchanging contact information</p> */}
            
            <div style={{ 'fontFamily': 'jakarta' }} className='footer-links-holder'>
                <div className='shop-company'>
                    <div className='shop-list' style={{alignItems:'center',width:'33.3%'}}>
                    <p>Shop</p>
                    <ul className='footer-list'>
                        <li> <Link to="/"> Home </Link></li>
                        <li> <Link to="/Products"> Products </Link></li>
                        <li> <Link to="/Business"> Business </Link></li>
                        <li> <Link to="/About"> About </Link></li>
                    </ul>
                </div>
                <div className='company-list' style={{alignItems:'center',width:'33.3%'}}>
                    <p>Company</p>
                    <ul className='footer-list'>
                        <li> <Link to="/About"> FAQ </Link></li>
                    </ul>
                </div>
                <div className='newsletter' style={{alignItems:'center',width:'33.3%'}}>
                    <p>Join us</p>
                    <form id='footer-newsletter' onSubmit={e=>handleClick(e)}>
                        <span> Sign-up for the latest news, offers, and much more. </span>
                        <div className='input-holder'>
                            <FontAwesomeIcon icon={faEnvelope} style={{ color: "#000000"}} />
                            <input
                                className='email-input'
                                type="email"
                                placeholder='Enter your email'
                                value={email}
                                onChange={e=>setEmail(e.target.value)}
                            />
                            <button type="submit" className='newsletter-button'> <FontAwesomeIcon icon={faArrowRight} style={{ 'color': '#ffffff' }} /> </button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
            
            
            
            <div style={{ 'fontFamily': 'jakarta' }} className='footer-links-holder-mobile'>
                <div className='shop-company'>
                    <div className='shop-list'>
                    <p>Shop</p>
                    <ul className='footer-list'>
                        <li> <Link to="/"> Home </Link></li>
                        <li> <Link to="/Products"> Products </Link></li>
                        <li> <Link to="/Business"> Business </Link></li>
                        <li> <Link to="/About"> About </Link></li>
                    </ul>
                </div>
                <div className='company-list'>
                    <p>Company</p>
                    <ul className='footer-list'>
                        <li> <Link to="/About"> FAQ </Link></li>
                    </ul>
                </div>
                </div>
                <div className='newsletter'>
                    <p>Join us</p>
                    <form className='footer-newsletter' onSubmit={e=>handleClick(e)}>
                        <span> Sign-up for the latest news, offers, and much more. </span>
                        <div className='input-holder'>

                            <input
                                className='email-input'
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                                placeholder='Enter your email'
                            />
                            <button className='newsletter-button' type="submit"> <FontAwesomeIcon icon={faArrowRight} style={{ 'color': '#ffffff' }} /> </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='footer-socialmedia'>
                <div className="apps">
                    <ul className='footer-list socialmedia-list'>
                        <li> <a aria-label='Cardtap Email' href="mailto:Info@card-tap.com"> <FontAwesomeIcon icon={faEnvelope} style={{ color: "#000000", }} size='2xl'/> </a></li>
                        <li> <a href='https://wa.me/201080124390' target='_blank'><FontAwesomeIcon icon={faWhatsapp} style={{ color: "#000000",width:35,height:40}} size='2xl'/></a></li>
                        <li> <a href='tel:+201080124390' target='_blank'><FontAwesomeIcon icon={faPhone} style={{ color: "#000000",width:35,height:35 }}/></a></li>
                        <li> <a aria-label='Cardtap Instagram' href="https://instagram.com/cardtap_?igshid=MzRlODBiNWFlZA=="> <FontAwesomeIcon icon={faInstagram} style={{ color: "#000000", }} size='2xl' /> </a></li>
                        <li> <a aria-label='Cardtap Facebook' href="https://www.facebook.com/profile.php?id=100063620868546&mibextid=ZbWKwL"> <FontAwesomeIcon icon={faFacebook} style={{ color: "#000000", }} size='2xl' /> </a></li>
                    </ul>
                </div>
                <p>2023 cardtap. All Rights Reserved</p>
            </div>
        </div>
    );
}

export default Navbar