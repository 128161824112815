import { Link } from "react-router-dom";
import section3Img2 from "../assets/images/section3-img2.png"
import moza2 from "../assets/images/mob2.png"
import circles from "../assets/images/circ.png"
import Marquee from "react-fast-marquee";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import mas from '../assets/images/logo-11.png'
import v from '../assets/images/logo-12.png'
import linux from '../assets/images/logo-13.png'
import gouna from '../assets/images/logo-14.png'
import lmd from '../assets/images/logo-15.png'
import prem from '../assets/images/logo-16.png'
import sim from '../assets/images/logo-17.png'
import magh from '../assets/images/logo-18.png'
import externa from '../assets/images/logo-19.png'
import uoc from '../assets/images/logo-20.png'
import hwala from '../assets/images/logo-21.png'
import ebs from '../assets/images/logo-22.png'
import dawarha from '../assets/images/logo-23.png'
import allianz from '../assets/images/logo-24.png'
import holyShift from '../assets/images/mail.png'
import mersal from '../assets/images/mersal-foundation.png'
import { useEffect, useState } from "react";
import { useCartContext } from "../hooks/useCartContext";
import swal from 'sweetalert';

const HomePage = () => {

    const [products,setProducts] = useState()

    useEffect(()=>{
        const getProducts = async () =>{
            try{
                const res = await fetch(`/products/getProducts`)
                console.log(res)
                let json = await res.json()
                json = [...(json.filter((x,idx) => idx%2!=0))]
                setProducts(json)
            }
            catch(err){

                console.log(err)
            }
        }
        getProducts()
    },[])

    const { cart, dispatch } = useCartContext();
    useEffect(()=>{
        localStorage.setItem('cart',JSON.stringify(cart));
    },[cart])

    const [addClicked,setAddClicked] = useState(null)


    const handleAddToCart = (data) => {
        console.log(data);
        let arr = cart;
        let itemIndex = arr.findIndex(x=> x._id == data._id)
        console.log(itemIndex)
        if(itemIndex>-1){
            console.log(arr[itemIndex])
            arr[itemIndex].quantity+=1
            dispatch({type:'UPDATE_ITEM',payload:arr});
        }
        else{
            data.quantity=1
            dispatch({type:'ADD_ITEM',payload:data}); 
        }
        swal({
            title: "Added to cart successfuly",
            icon: "success",
            button: "Continue shopping",
        });
        setTimeout(() => {
            setAddClicked(false)
        }, 1000);    
    }



    const Logos1 =
        [
            { id: 1, src: mas, alt: 'Viber' },
            { id: 2, src: v, alt: 'Vichy' },
            { id: 3, src: linux, alt: 'Orange' },
            { id: 4, src: gouna, alt: 'NovaNordic' },
            { id: 5, src: lmd, alt: 'NovaNordic' },
            { id: 6, src: allianz, alt: 'Viber' },
            { id: 7, src: prem, alt: 'Vichy' },
            { id: 8, src: sim, alt: 'Orange' },
            { id: 9, src: magh, alt: 'NovaNordic' },
            { id: 10, src: externa, alt: 'NovaNordic' },
            { id: 11, src: uoc, alt: 'Viber' },
            { id: 12, src: hwala, alt: 'Vichy' },
            { id: 13, src: ebs, alt: 'Orange' },
            { id: 14, src: dawarha, alt: 'NovaNordic' },
            { id: 15, src: mersal, alt: 'mersal' },
            { id: 16, src: holyShift, alt: 'Holy Shift' },

        ]

    return (
        <div className="homePageContainer">
            <div className="section1-mobile">
                <div>
                    <div className="wrapper-home">
                        <h1 className="wordsContainer-home">Share your <br /> contact
                            <div className="words-home">
                                <span>faster</span>
                                <span>easily</span>
                                <span>with just</span>
                                <span>one tap</span>
                            </div>
                        </h1>
                    </div>
                    <p>
                        Embrace NFC Technology with Top-Notch NFC Business cards in Egypt
                    </p>
                </div>
                <div className="section1-mobile-img">
                </div>
            </div>
            <Link className="home-but home-but-mobile" to="/Products"> Get Yours </Link>
            <div className="section1" >
            {/* <img style={{position:'absolute',top:'0',left:'0',zIndex:'-1',width:'900px',height:'1200px'}} src={homeIcon}></img> */}
            <div>
                <div className="wrapper-home">
                    <h1 className="wordsContainer-home">Share your <br /> contact
                        <div className="words-home">
                            <span>faster</span>
                            <span>easily</span>
                            <span>with just</span>
                            <span>one tap</span>
                        </div>
                    </h1>

                </div>
                <div>
                <h2 style={{fontSize:'1.3vw'}}>
                    Embrace NFC Technology with <br /> Top-Notch NFC Business cards in Egypt
                </h2>
                </div>
                <div style={{'marginTop': '100px'}}><Link className="home-but" to="/Products"> Get Yours </Link></div>


                {/* <div class="outer">
                        <h1>Share your <br/> contact
                            <div class="inner">
                                <span>
                                    faster<br/>
                                    easily<br/>
                                    with<br/> just<br/>
                                    one <br/> tap<br/>
                                </span>
                            </div>
                        </h1>
                    </div> */}



                {/* <div className="section1-right">
                    <img src={alaa} />
                </div> */}
            </div>
            </div>
            <div className="section3">
                <h1>With one tap, a lot happens</h1>
                <div className="lower">
                    <div className="section3-left">
                        <h2>
                            All in one NFC business card presenting your business in a professional way
                        </h2>
                    </div>
                    <div className="section3-right">
                        <img alt="" src={moza2} />
                    </div>
                </div>
            </div>
            <div className="section3-desktop">
                <h1>
                    With one tap, Alot happens
                </h1>
                <h2 style={{ 'fontWeight': '500' }}> All in one NFC business card presenting your <br/> business in a professional way </h2>
                <div className="brief-lower">
                    <div> <div className="brief-div"><img src={section3Img2} /> <span>No App!</span></div>  <p> No need for an application to share your Information through cardtap’s NFC business cards  </p></div>
                    <div> <div className="brief-div"> <img src={section3Img2} /> <span>Connected</span> </div><p> Stay connected with cardtap's wide range of NFC <Link to="/products" style={{ 'fontFamily': 'poppins', 'textDecoration': 'underline', 'fontWeight': 'bold' }}>Products</Link>, designed for convenience and efficiency. </p></div>
                    <div> <div className="brief-div"> <img src={section3Img2} /> <span>Unlimited</span> </div>  <p>Discover the limitless potential of NFC technology with our innovative NFC business cards and tags.</p></div>
                </div>
            </div>
            <div className="section4-desktop">

            </div>
            <div className="section4">
                <div className="cardsHolder-home">
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={1.2}
                       
                    >
                        <SwiperSlide>
                            <div className="card">
                                <h3>No App!</h3>
                                <p>No need for an application to share your Information through cardtap’s NFC business cards</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <h3>Connected</h3>
                                <p> Stay connected with cardtap's wide range of NFC products, designed for convenience and efficiency. </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <h3>Unlimited</h3>
                                <p> Discover the limitless potential of NFC technology with our innovative NFC business cards and tags. </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div>
                    <div>
                        <img src={circles} />
                    </div>
                </div>
            </div>
            <div className="section5-desktop">
                <div className="sec5-left">
                    <h1>
                        How it works
                    </h1>
                    <h2 style={{ 'fontWeight': '500' }}> Edit and Share Your Profile Effortlessly with Our One-Tap Solution </h2>
                    <div className="brief-lower-sec5-brief">
                        <div> <div className="brief-div"><img src={section3Img2} /> <span style={{ 'fontFamily': 'poppins' }}>Profiles</span></div>  <p style={{ 'fontFamily': 'poppins' }}>  Customize Your Card: Select the design and information you want to share on your NFC business card. </p></div>
                        <div> <div className="brief-div"> <img src={section3Img2} /> <span style={{ 'fontFamily': 'poppins' }}>Editable</span> </div><p style={{ 'fontFamily': 'poppins' }}> Tap and Share: When meeting someone, simply tap your NFC business card to their smartphone. It's that easy! </p></div>
                        <div> <div className="brief-div"> <img src={section3Img2} /> <span style={{ 'fontFamily': 'poppins' }}>All in one</span> </div>  <p style={{ 'fontFamily': 'poppins' }}>Instant Connection: Your contact information, website, and social media links will be instantly accessible to the recipient.</p></div>
                    </div>
                </div>
                <div className="sec5-right">
                </div>
            </div>
            <div className="section5">
                <img src="https://res.cloudinary.com/cardtap/image/upload/v1697503890/ezgif.com-optimize_h9vhy8.gif" />
                <h1>How it Works</h1>
                <p className="sec5-p"> Edit and Share Your Profile Effortlessly with Our One-Tap Solution </p>
                <div className="cardsHolder-home cards-holder-sec5">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1.2}
                    
                    >
                        <SwiperSlide>
                            <div className="card card-sec5">
                                <h3>Profiles</h3>
                                <p>Customize Your Card: Select the design and information you want to share on your NFC business card.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-sec5">
                                <h3>Editable</h3>
                                <p> Tap and Share: When meeting someone, simply tap your NFC business card to their smartphone. It's that easy!</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-sec5">
                                <h3>All in One</h3>
                                <p> Instant Connection: Your contact information, website, and social media links will be instantly accessible to the recipient. </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>



                </div>
            </div>
            <div className="section6-desktop">
                <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    spaceBetween={25}
                    slidesPerView={4}
                
                >
                    {
                       products && products.map((prod,idx)=>(
                        <SwiperSlide key={idx}>
                        <div className="productCard">
                            <Link to={"/products/"+prod.URL_Name} className="productImgHolder-home">
                                <img src={prod.imgLink} alt="Cardtap's digital NFC business card" />
                            </Link>
                            <p>{prod.name}</p>
                            {prod._id != "651b3dfa2b4af7127cb8ef4c" ?
                            <Link
                                className={addClicked === prod._id ? "button-HomePage-clicked" : "section6-button"}
                                disabled={addClicked === prod._id}
                                onClick={(e) => {
                                    setAddClicked(prod._id); // Set addClicked to the product's id
                                    handleAddToCart(prod);
                                }}
                            >
                                Add to cart
                            </Link>
                            :
                            <Link className="section6-button" to={"/products/"+prod._id}> View Product </Link>
                            }                        
                        </div>
                        <div style={{ 'height': '100px' }} />
                    </SwiperSlide>
                       ))
                    }
                </Swiper>

            </div>
            <div className="section6">
                <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    spaceBetween={50}
                    slidesPerView={1}
                 
                >
                    
                    {
                       products && products.map((prod,idx)=>(
                        <SwiperSlide key={idx}>
                        <div className="productCard">
                            <Link to={"/products/"+prod.URL_Name} className="productImgHolder-home">
                                <img src={prod.imgLink} alt="Cardtap's digital NFC business card" />
                            </Link>
                            <p>{prod.name}</p>
                            {prod._id != "651b3dfa2b4af7127cb8ef4c" ?
<                               Link className={addClicked ? "button-ProductsPage-clicked" :"section6-button"} disabled={addClicked} onClick={(e) => {setAddClicked(true);handleAddToCart(prod)}}> Add to cart </Link>
                                :
<                               Link className="section6-button" to={"/products/"+prod._id}> View Product </Link>
                            }
                        </div>
                        <div style={{ 'height': '100px' }} />
                    </SwiperSlide>
                       ))
                    }
                </Swiper>

            </div>
            <div className="seeProductsContainer">
            <Link className="seeProducts" to="/products"> See all products {">"}{">"} </Link>
            </div>
            <div className="section7">
                <h1>Our Beloved Clients</h1>
                <div className="logos-holder">
                    <Marquee autoFill={true} speed={65} direction="right">
                        {Logos1.map((image, idx) => (
                            <img src={image.src} alt={image.alt} key={idx} />
                        ))}
                    </Marquee>
                </div>
            </div>
        </div>
    );
}


export default HomePage