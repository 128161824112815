import { useEffect } from "react";
import ContactCard from "./ContactCard";
import { useProfileContext } from "../hooks/useProfileContext";
import { useOutletContext } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
const Contacts = () =>{
    const {profile} = useProfileContext()
    const [handleTutorialClick] = useOutletContext();
    useEffect(() => {
        if (handleTutorialClick[0]) {
            console.log('Start Tutorial button clicked in Outlet component');
            const driverObj1 = driver({
                showProgress: true,  // Because everyone loves progress bars!
                steps: [
                    {
                        element: '.myContactsContainer',
                        popover: {
                            title: 'Contacts Page.',
                            description: `Here are your incoming contacts that came when your customer clicked on "EXCHANGE CONTACT" button in your profile and filled his information.`
                        }
                    },
                    {
                        element: '.myContactsCardContainer',
                        popover: {
                            title: 'Contact Card',
                            description: "Here is new contact icluding the information he sent."
                        }
                    },
                    {
                        element: '.save-ex',
                        popover: {
                            title: 'Download Contact',
                            description: `Here you can download your new contact to be saved on your mobile.`
                        }
                    }
                ]
            });
            driverObj1.drive();

            // Call your function or perform any other actions here
        }
     }, [profile,handleTutorialClick])

    return(
        <div className="myContactsContainer">
            <h1>Incoming Contacts</h1>
            <div className="myContactsHolder">
                {profile.profiles[profile.activeProfile].clientsContacts.map((user,idx)=>(
                    <ContactCard contact={user} id={idx} key={idx} />
                ))}               
            </div>
        </div>
    );
}

export default Contacts