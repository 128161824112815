import React, { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import loginImg from '../assets/images/login.png'
import logoGrad from '../assets/images/logo2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet-async';

const Login = () => {
    const [Email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { login, error, isLoading } = useLogin();

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(password, Email);
        await login(Email, password);
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (<div>
        <Helmet>
        <title>Cardtap | Login to your account </title>
        </Helmet>
        <div className="loginHolder">
            <div className="loginContainer">
                <div className="login-img" style={{ 'background': `url(${loginImg})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center', 'backgroundRepeat': 'no-repeat' }} />
                <form className="loginForm" onSubmit={handleSubmit}>
                    <div className="login-part1"> <img src={logoGrad} /> </div>

                    <div className="login-part2">
                        <h3 style={{ 'fontFamily': 'poppins','fontSize':'14px' }}>Nice to see you again</h3>
                        {error && <Alert severity="error">{error}</Alert>}
                        <div className="login-input">
                            <label>Login</label>
                            <div className="password-input">
                                <input
                                    type="email"
                                    placeholder="Enter Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={Email}
                                    required
                                    name="email"
                                    autocomplete="on"
                            />
                            </div>
                        </div>

                        <div className="login-input">
                            <label>Password</label>
                            <div className="password-input">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    name="password"
                                    autoComplete="on"
                                />
                                <span
                                    className={`password-toggle ${showPassword ? 'visible' : ''}`}
                                    onClick={handleTogglePassword}
                                >
                                    <FontAwesomeIcon icon={faEye} style={{ color: "#453a3a" }} />
                                </span>
                            </div>
                        </div>

                        <Link className="pass-link" to="/forget-password"> Forgot your password? </Link>
                        <button type="submit">{!isLoading? 'Login' : <CircularProgress />}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default Login;
