import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/images/logo.png'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useCartContext } from "../hooks/useCartContext";
import CartItems from "./CartItems";
import { useNavigate} from "react-router-dom"
import { useLocation } from "react-router-dom";

const Footer = () => {
    const { cart, dispatch } = useCartContext();
    const [pokeOpen, setPokeOpen] = useState(false);
    const [isCheckoutPage, setIsCheckoutPage] = useState(false);
    const handlePokeOpen = () => setPokeOpen(true);
    const handlePokeClose = () => setPokeOpen(false);
    const location = useLocation();
    const navigate = useNavigate();
    const backToHome = ()=>navigate('/')
    const cartItemsCount = () =>{
        let count = 0
        cart.forEach(item=>{
            count +=item.quantity
        })
        return count;
    }
    useEffect(()=>{
        if(location.pathname==='/checkout') setIsCheckoutPage(true)
        else setIsCheckoutPage(false)
    })
    return (
        <nav className="navbar">
            <div className="imgHolder">
                <img src={Logo} alt="Cardtap's logo" onClick={backToHome} style={{cursor:'pointer','width':'100%'}}/>
            </div>
            <ul className="list">
                <li> <Link className="hover" to="/"> HOME </Link> </li>
                <li> <Link className="hover" to="/Products"> PRODUCTS </Link> </li>
                <li> <Link className="hover" to="/Business"> BUSINESS </Link> </li>
                <li> <Link className="hover" to="/About"> ABOUT </Link> </li>
                <li> <Link className="loginButton" to="/login"> LOGIN </Link> </li>
                { !isCheckoutPage&& 
                <li style={{flexDirection:'row'}}>  
                    <Button style={{ 'color': 'black','paddingRight':'0','justifyContent':'flex-end',background:'none' }} className='taskOpenBtn' onClick={handlePokeOpen}>
                        <FontAwesomeIcon className="cart" icon={faBagShopping} size="10x" style={{ color: "#000000", }} />
                    </Button>
                    {cart.length >= 0 && (
                        <div className="cart-badge">
                            {cartItemsCount()}
                        </div>
                    )}
                </li>
                }
                <Modal
                    open={pokeOpen}
                    onClose={handlePokeClose}
                >
                    <Box >
                        <div className='cartContainer'>
                            <div className="cartItemsHolder">
                                <h3 style={{'fontSize':'25px','marginBottom':'2%'}}>Shopping Cart</h3>
                                <h5 className="itemsCount">You have {cartItemsCount()} items in your cart.</h5>
                                <hr/>
                                {cart && cart.map((item,index)=>(
                                    <CartItems key={index} props={item} />
                                ))}
                                {cart.length!=0&&
                                <div className="checkoutButtonContainer">
                                    <Link to='/checkout' className="checkoutButton" onClick={handlePokeClose}>
                                        Go To Checkout
                                    </Link>
                                </div>
                                }
                            </div>
                        </div>
                    </Box>
                </Modal>
            </ul>
        </nav>
    );
}


export default Footer