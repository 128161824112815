import { useOutletContext, useParams } from "react-router";
import { useProfileContext } from "../../hooks/useProfileContext";
import { useEffect, useState } from "react";
import EmployeeCard from './EmployeeCard';
import CorporateMocket from "./CorporateMocket";
import { useCompanyEmployeesContext } from "../../hooks/useCompanyEmployeesContext";
const EditEmployee = () =>{
    const {profile , dispatch} = useProfileContext();
    const {companyName} = useParams()
    const {employees} = useCompanyEmployeesContext();
    const employeesProfiles = employees
    let newEmployeesOrder = [];
    if(companyName === "AAST"){
        for(let i of employees){
            if(i.manager==="AAST"){
                newEmployeesOrder.unshift(i);
            }   
            else{
                newEmployeesOrder.push(i)
            }
        }
    }
    else{
        newEmployeesOrder = employeesProfiles
    }
    console.log(newEmployeesOrder)
    const [searchResults,setSearchResults] = useState(newEmployeesOrder)
    const [selectedProfile, setSelectedProfile] = useState()
    const handleSearch = (e) =>{
        console.log(e.target.value)
        if(e.target.value === "")
            setSearchResults(employeesProfiles)
        else
            setSearchResults([...(employeesProfiles.filter(w => w.name.toLowerCase().includes(e.target.value.toLowerCase()) ))])
    } 

    useEffect(()=>{
        console.log(profile)
        setSelectedProfile(profile)
    },[profile,employees])

    const handleClick = (employee) =>{
        setSelectedProfile(employee)
        dispatch({type:'SET_PROFILES',payload:employee})
    }
    console.log(employees)
    return(
        <div className="edit-employees-holder"> 
            <div style={{'width':'100%'}} className='searchBar'>
                    <input onChange={e=>handleSearch(e)} type="text" placeholder='Search for an employee' />
            </div>
            <div className="socialMediaHolder employee-edit-container">
                <div className="profileView-leftPart corporate-edit">
                    {selectedProfile && <CorporateMocket profile={selectedProfile} />}
                </div>
                <div className="profileView-rightPart-corporate-edit">
                    <div className='cardsHolder corporate-edit-card-holder-search'>
                        <div className='cardsHolder-2 employee-edit-card-holder'>
                            {searchResults.map((employee,idx)=>(
                                <div key={idx} onClick={e=> handleClick(employee)} className="employee-edit-card-container">
                                    <EmployeeCard employee={employee} idx={idx} key={idx} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditEmployee