import { useLoaderData } from "react-router-dom";
import '../assets/css/products.css';
import ProductHolder from "../components/ProductHolder";
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

const ProductsPage = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts (new route is loaded)
        window.scrollTo(0, 0);
    }, []);

    const [products, offers] = useLoaderData();
    //let offers = [products[products.length - 1], products[products.length - 2]];

    return (
        <div>
            <Helmet>
                <title>Cardtap | Discover a Diverse Range of Digital NFC Products</title>
                <meta name="description" content="Explore Cardtap's wide range of NFC products for seamless connectivity and convenience. Check out Cardtap’s latest collection of NFC plastic, metal cards and NFC tags." />
            </Helmet>
            <div className="productsContainer">
                <div className="productsHolder">
                    
                    {products && products.map((prod, index) => (
                        <ProductHolder key={index} props={prod} />
                    ))}
                </div>
            </div>
        </div>
    );
}
export default ProductsPage

export const GetProducts = async () => {
    const response = await fetch('/products/getProducts')
    const json = await response.json()
    if(json[json.length - 1].name==='Cardtap NFC Business Metal Card & Tag Offer'){
        let offers = [json[json.length - 1], json[json.length - 2]];
        json.pop()
        json.pop()
        return [json, offers];
    }
   else{
    return [json, []];
   }
    
}