import { CartContext } from "../context/CartContext";
import { useContext } from "react";

export const useCartContext = () =>{
    const context = useContext(CartContext)
    
    if(!context) {
        throw Error('useSocketContext must be used inside an SocketContextProvider')
      }
    return context
}