import { useState } from 'react'
import { useUserContext } from './useUserContext'
import {useProfileContext} from './useProfileContext'
import axios from 'axios';
export const useLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useUserContext()
    const { dispatch : update } = useProfileContext() 
    const login = async (Email, password) => {
        setIsLoading(true)
        setError(null)
        let cred = JSON.stringify({ email:Email, password:password })
        const response = await axios.post(`/users/login`,cred ,{
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
        .then(async (res)=>{
            console.log(res)
            const json = res.data
            console.log(json);
            // save the user to local storage
            if(!json.error && json.user !=='user is not found'){
                localStorage.setItem('user', JSON.stringify(json))
                // update the auth context
                dispatch({ type: 'LOGIN', payload: json })
                update({ type: 'SET_PROFILES', payload: json.user })
                // update loading state
            }
            else{
                setError('Email or Password is incorrect.')
            }
            setIsLoading(false)
            
        })
        .catch((err)=>{
            console.log(err)
            console.log("No internet Connection")
        })
    }

    return { login, isLoading, error }
}