// import { useCartContext } from "../hooks/useCartContext";

const CheckoutItem = ({item}) =>{
    console.log(item)
    const calculatePriceAfterSale = () => {
        let price = parseInt(item.price) + 1
        let sale = item.sale/100
        return price - (price*sale) - 1 
    }
    return(
        <div className="itemContainer">
            <div className="itemImgCheckout">
                <img src={item.imgLink} />
            </div>
            <div className="itemDetails">
                <p>{item.name}</p>
                { item.sale!=0?
                <span> {calculatePriceAfterSale().toFixed(2)} LE </span>
                :
                <span> {(item.price)} </span>
                }
                <span style={{marginTop:'4%'}}> Quantity: {item.quantity} </span>
                { item.sale!=0? 
                <span style={{marginTop:'4%'}}> Total: {(item.quantity*calculatePriceAfterSale()).toFixed(2)} LE </span>
                :
                <span style={{marginTop:'4%'}}> Total: {(item.quantity*parseInt(item.price)).toFixed(2)} LE </span>
                }
                {item.offer &&
                <span style={{marginTop:'4%'}}> Chosen items: {item.offer[0]} & {item.offer[1]} </span>
                }
            </div>
        </div>
    );
}

export default CheckoutItem