import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import './assets/css/navbar.css';
import './assets/css/home.css';
import './assets/css/productDescription.css';
import './assets/css/business.css';
import './assets/css/login.css'
import './assets/css/dashboard.css'
import './assets/css/analytics.css'
import './assets/css/mobile.css'
import './assets/css/profileView.css'
import './assets/css/profiles.css'
import './assets/css/socialmedia.css'
import './assets/css/contactinfo.css'
import './assets/css/cart.css'
import './assets/css/businessinfo.css'
import './assets/css/checkout.css'
import './assets/css/mainPage.css'
import './assets/css/edit.css'
import './assets/css/corporateanalytics.css'
import './assets/css/imageslider.css'
import './assets/css/mycontacts.css'
import './assets/css/about.css'
import './assets/css/thankyou.css'

import App from './App';
import { CartContextProvider } from './context/CartContext';
import { UserContextProvider } from './context/UserContext';
import { ProfileContextProvider } from './context/ProfileContext';
import { CompanyEmployeesContextProvider } from './context/CompanyEmployeesContext';
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <UserContextProvider>
        <ProfileContextProvider>
          <CompanyEmployeesContextProvider>
            <CartContextProvider>
              <App />
            </CartContextProvider>
          </CompanyEmployeesContextProvider>
        </ProfileContextProvider>
      </UserContextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals