import logo from '../assets/images/font-logo.png';
import Platform from './Platform';
import { useProfileContext } from '../hooks/useProfileContext';
import BusinessLinks from './BusinessLinks';
import { useState } from 'react';

const MobileMocket = ({profile}) =>{
    const {profile:profileContext} = useProfileContext();
    const [showMore, setShowMore] = useState(false);
    let c;
    if (profile.colorTheme === '#d9d9d9') {
        c = '217, 217, 217'
        console.log(c)
    }
    else {
        c = profile.colorTheme
    }
    return(
        <div className="profileContainer">
            <div className="mocketLogo">
                <img src={logo}/>
            </div>
            <div className="mocketCover">
                <img src={profile.image2} />
            </div>
            <div className="imgHolder">
                <div className="mocketImage">
                    <img src={profile.image1}/>
                </div>
            </div>
            <div className='nameHolder'>
                <h1> {profileContext.name} </h1>
                <h4> {profile.job}</h4>
            </div>
            <div className="dashcontactButtons">
                <a style={{'pointerEvents':'none','background':`rgba(${c},1)`,'color':'black','width':'105%','fontWeight':'800' }} href={profile.contact_link}> SAVE CONTACT </a>
                <a style={{'pointerEvents':'none'}}> EXCHANGE INFO </a>
            </div>
            { profile.bio.length>3 && <div className="bio">
                    <div className='bioTitle'>BIO</div>
                    <p style={{ 'background': `rgba(${profile.colorTheme},.4)` }}>
                        {showMore ? profile.bio : `${profile.bio.substring(0,250)}`}
                        {profile.bio.length>250 && <span style={{'borderBottom':'1px solid','color':'darkgray'}} onClick={() => setShowMore(!showMore)} > {showMore? 'Show less':'Show more'} </span>}
                    </p>
                </div>}
            <div className="platformsHolder">
                {profile.socialMedia && profile.socialMedia.map((app,index)=>(
                    <Platform corp={true} idx={index} key={index} props={app} color={profile.colorTheme} />
                ))}
            </div>
            <div className="businessLinkHolder">
                {profile.businessInfo && profile.businessInfo.map((app,index)=>(
                    <BusinessLinks corp={true} key={index} props={app} color={profile.colorTheme} idx={index}/>
                ))}
            </div>
        </div>
    );
}
export default MobileMocket;