import { useEffect} from "react";
import { useProfileContext } from "../hooks/useProfileContext";
import 'react-phone-number-input/style.css'
import MobileMocket from "./MobileMocket";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import * as React from 'react';
import { useState } from "react";
import Facebook from '../assets/images/Facebook.png'
import Instagram from '../assets/images/Instagram.png'
import x from '../assets/images/x.png'
import LinkedIn from '../assets/images/LinkedIn.png'
import Youtube from '../assets/images/Youtube.png'
import wechat from '../assets/images/wechat.png'
import github from '../assets/images/github.png'
import tiktok from '../assets/images/tiktok.png'
import whatsapp from '../assets/images/Whatsapp.png'
import pinterist from '../assets/images/pinterist.png'
import behance from '../assets/images/behance.png'
import music from '../assets/images/music.png'
import spotify from '../assets/images/spotify.png'
import deezer from '../assets/images/deezer.png'
import messenger from '../assets/images/messenger.png'
import snapchat from '../assets/images/snapchat.png'
import telegram from '../assets/images/telegram.png'
import reddit from '../assets/images/reddit.png'
import shorts from '../assets/images/shorts.png'
import podcast from '../assets/images/podcast.png'
import threads from '../assets/images/threads.png'
import dribble from '../assets/images/dribble.png'
import medium from '../assets/images/medium.png'
import discord from '../assets/images/discord.png'
import stackoverflow from '../assets/images/stackoverflow.png'
import icloud from '../assets/images/icloud.png'
import msteams from '../assets/images/msteams.png'
import zoom from '../assets/images/zoom.png'
import googlemeet from '../assets/images/googlemeet.png'
import google from '../assets/images/google.png'
import gmail from '../assets/images/gmail.png'

import application from '../assets/images/application.png'
import Platform from "./Platform";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../hooks/useUserContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { PhoneNumberUtil } from 'google-libphonenumber';
import axios from 'axios';
import { useOutletContext } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useCompanyEmployeesContext } from "../hooks/useCompanyEmployeesContext";
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  }; 
const SocialMedia = () => {
    const { profile, dispatch } = useProfileContext();
    const { employees, dispatch:dispatchEmployees } = useCompanyEmployeesContext();

    console.log(profile.profiles[profile.activeProfile])
    const [handleTutorialClick] = useOutletContext();
    const { user } = useUserContext();
    const [isLoading, setIsLoading] = useState(false)
    const [socialMedia, setSocialMedia] = useState('');
    const [appSelected, setAppSelected] = useState([application, '', '']);
    const [inputOptions, setInputOptions] = useState(application);
    const [link, setLink] = useState();
    const [value, setValue] = useState()
    let isValid = isPhoneValid(link);
    useEffect(() => {
        if (handleTutorialClick[0]) {
            console.log('Start Tutorial button clicked in Outlet component');
            const driverObj1 = driver({
                showProgress: true,  // Because everyone loves progress bars!
                steps: [
                    {
                        element: '.profileContainer',
                        popover: {
                            title: 'Your Profile',
                            description: "Here are your profile overview. You can delete any social media application or business information by clicking on the minus button."
                        }
                    },
                    {
                        element: '.linksUpper',
                        popover: {
                            title: 'Social Media Apps.',
                            description: 'Here you can choose any social media application to add it to your activated profile.'
                        }
                    },
                    {
                        element: '.smInputContainer',
                        popover: {
                            title: 'Social Media Application Input',
                            description: 'Here after selecting the desired application you should put your social media link, based on the place holder inside the input. (ps. there applications the needs full url link while other needs username.).'
                        }
                    }
                ]
            });
            driverObj1.drive();

            // Call your function or perform any other actions here
        }
    }, [handleTutorialClick])

    let regexMap = new Map();
    {
        regexMap.set('facebook', "*");
        regexMap.set('instagram', "*")
        regexMap.set('github', "^(http(s?):\\/\\/)?(www\\.)?github\\.([a-z])+\\/([A-Za-z0-9]{1,})+\\/?$")
        regexMap.set('x', "*")
        regexMap.set('tiktok', "https://www.*")
        regexMap.set('youtube', "*")
        regexMap.set('messenger', "^(http(s)?:\\/\\/)(www\\.)?m.me\\.?(com)?\\/.*")
        regexMap.set('snapchat', "*")
        regexMap.set('telegram', "^(http(s)?:\\/\\/)(www\\.)?(telegram|t)\\.me\\/.*")
        regexMap.set('reddit', "*")
        regexMap.set('shorts', "^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$")
        regexMap.set('threads', "^(http(s)?:\\/\\/)?(www\\.)?threads\\.net\\/@[A-z 0-9 _]{1,15}\\/?$")

        //TAREK 
        //teams ma3mol bel email directly
        regexMap.set('msteams', "^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$")
        //linkedin mn el link dah https://regex101.com/r/cI4gH9/1
        regexMap.set('LinkedIn', "^https:\\/\\/[a-z]{2,3}\\.linkedin\\.com\\/.*$")
        //stackoverflow mn repo github el kbera deh bas ksom el b3bsa el ana 3mltaha 7rfyan 3mlt regex 8ayrt 7etat mnha a5dtha mn teams xD 
        regexMap.set('stackoverflow', "(?:https?:)?\\/\\/(?:www\\.)?stackoverflow\\.com\\/users\\/([0-9]+)\\/([a-zA-Z0-9_\\-\\.]+)\\/?")
        //discord dah el ID mazonsh en fe 7aga 8eroh lao ana fakr 
        regexMap.set('discord', "*")
        //spotify .* fel a5r 5ly balk keda b2ena 3amlen 6 regex 555 7otaha fe 3en at5n sherka
        regexMap.set('spotify', "(https?:\\/\\/open.spotify.com\\/(track|user|artist|album)\\/[a-zA-Z0-9]+(\\/playlist\\/[a-zA-Z0-9]+|)|spotify:(track|user|artist|album):[a-zA-Z0-9]+(:playlist:[a-zA-Z0-9]+|)).*")
        //google covering both variations msh 3arf feh talt wla la2
        regexMap.set('google', "^[a-z0-9]((\\.|\\+)?[a-z0-9]){5,}@g(oogle)?mail\\.com$")
    }
    const navigate = useNavigate();
    const smApps = [
        [Instagram, 'instagram', regexMap.get('instagram')],
        [x, 'x', regexMap.get('x')],
        [Facebook, 'facebook', regexMap.get('facebook')],
        [tiktok, 'tiktok', regexMap.get('tiktok')],
        [Youtube, 'youtube', regexMap.get('youtube')],
        [whatsapp, 'whatsapp', regexMap.get('whatsapp')],
        [messenger, 'messenger', regexMap.get('messenger')],
        [snapchat, 'snapchat', regexMap.get('snapchat')],
        [telegram, 'telegram', regexMap.get('telegram')],
        [reddit, 'reddit', regexMap.get('reddit')],
        [shorts, 'shorts', regexMap.get('shorts')],
        [threads, 'threads', regexMap.get('threads')],
        [LinkedIn, 'linkedin', regexMap.get('LinkedIn')],
        [wechat, 'wechat', regexMap.get('wechat')]
    ]
    const techApps = [
        [medium, 'meduim', regexMap.get('meduim')],
        [stackoverflow, 'stackoverflow', regexMap.get('stackoverflow')],
        [github, 'github', regexMap.get('github')]
    ]
    const artMusicApps = [
        [podcast, 'podcast', regexMap.get('podcast')],
        [music, 'music', regexMap.get('music')],
        [deezer, 'deezer', regexMap.get('deezer')],
        [spotify, 'spotify', regexMap.get('spotify')],
        [pinterist, 'pinterist', regexMap.get('pinterist')],
        [behance, 'behance', regexMap.get('behance')],
        [dribble, 'dribble', regexMap.get('dribble')]
    ]
    const businessApps = [
        [icloud, 'icloud', regexMap.get('icloud')],
        [gmail, 'google', regexMap.get('google')],
        [msteams, 'msteams', regexMap.get('msteams')],
        [googlemeet, 'googlemeet', regexMap.get('googlemeet')],
        [zoom, 'zoom', regexMap.get('zoom')],
    ]
    const handleChange = (src) => {
        setAppSelected(src)
    };


    const handleAppSave = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        console.log(link);
        const formData = new FormData();
        let whatsAppLink = ''
        let snapchatLink = ''
        let instagramLink = ''
        let redditLink = ''
        let googleMail = ''
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', profile.activeProfile);
        formData.append('PlatformName', appSelected[1]);
        if (appSelected[1] === 'whatsapp') {
            let num = link.replace(' ', '')
            whatsAppLink = `https://wa.me/${num}/?text=Hello%20My%20Greetings`
            formData.append('PlatformLink', whatsAppLink);
        }
        else if (appSelected[1] === 'snapchat') {
            snapchatLink = `https://www.snapchat.com/add/${link}`
            formData.append('PlatformLink', snapchatLink);
        }
        else if (appSelected[1] === 'instagram') {
            instagramLink = `https://www.instagram.com/${link}/`
            formData.append('PlatformLink', instagramLink);
        }
        else if (appSelected[1] === 'reddit') {
            redditLink = `https://www.reddit.com/user/${link}/`
            formData.append('PlatformLink', redditLink);
        }
        else if (appSelected[1] === 'google') {
            googleMail = `mailto:${link}`
            formData.append('PlatformLink', googleMail);
        }
        else
            formData.append('PlatformLink', link);

        formData.append('PlatformIcon', appSelected[0]);

        try{
            await axios.post( `/users/updateSm`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    dispatch({ type: "UPDATE_PROFILES", payload: json[0]})
                    if(json[1]!== 0){
                        dispatchEmployees({type:"SET_EMPLOYEES",payload: json[1]})
                    }
                    setLink('')
                    setSocialMedia('')
                    setIsLoading(false)
                })
            // const response = await fetch( `/users/updateSm`,
            //     {
            //         method: 'POST',
            //         body: formData,
            //         headers: { 'Authorization': `Bearer ${user.token}` }
            //     }
            // )
            // const json = await response.json()
            // if (response.ok) {
                
            // }
        }
        catch(err){
            console.log("No internet connection")
        }
    }


    return (
        <div>
            {!isLoading ? <div className="socialMediaHolder">
                <div className="mobile-contactInfoHeader">
                    <h2 style={{fontFamily:'poppins',fontSize:'20px'}}>
                        Social Media
                    </h2>
                </div>
                <div className="profileView-leftPart">
                    <MobileMocket profile={profile.profiles[profile.activeProfile]} />
                </div>
                <div className="profileView-rightPart">
                    <div className="linksUpper">
                        <div className="sectionHolder-sm">
                            <p>Social Media</p>
                            <div className="appImgHolder">
                                {smApps && smApps.map((src, idx) => (
                                    <div key={idx}>
                                        <a href="#appSubmit">
                                            <img src={src[0]} onClick={e => handleChange(src)} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="sectionHolder-sm">
                            <p>Art & Music</p>
                            <div className="appImgHolder">
                                {artMusicApps && artMusicApps.map((src, idx) => (
                                    <div key={idx}>
                                        <a href="#appSubmit">
                                            <img src={src[0]} onClick={(e) => handleChange(src)} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="sectionHolder-sm">
                            <p> Tech </p>
                            <div className="appImgHolder">
                                {techApps && techApps.map((src, idx) => (
                                    <div key={idx}>
                                        <a href="#appSubmit">
                                            <img src={src[0]} onClick={(e) => handleChange(src)} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="sectionHolder-sm">
                            <p> Communication</p>
                            <div className="appImgHolder">
                                {businessApps && businessApps.map((src, idx) => (
                                    <div key={idx}>
                                        <a href="#appSubmit">
                                            <img src={src[0]} onClick={(e) => handleChange(src)} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="smInputContainer">
                        <form id="appSubmit" className="smInputHolder" onSubmit={(e) => handleAppSave(e)}>
                            <img src={appSelected[0]} />
                            <div className="vertical-space" />
                            {appSelected[1] !== 'whatsapp' ? <input pattern={appSelected[2]} placeholder={
                                appSelected[1] === 'snapchat' || appSelected[1] === 'instagram' || appSelected[1] === 'reddit'
                                    ? 'Please enter your platform username'
                                    : 'Please enter your platform link'
                            } onChange={e => (setLink(e.target.value))} type="text" required />
                                : <PhoneInput
                                    className="phoneInput"
                                    defaultCountry="eg"
                                    value={link}
                                    onChange={(phone) => setLink(phone)}
                                    forceDialCode={true}
                                />}
                        </form>
                        {
                            appSelected[1] === 'whatsapp'?  <button disabled={!isValid} className="buttonSM" form="appSubmit" type="submit"> Add</button>:<button className="buttonSM" form="appSubmit" type="submit"> Add</button>
                        }
                    </div>
                </div>
            </div> : <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
        </div>
    );
}

export default SocialMedia