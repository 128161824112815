import { useProfileContext } from "../hooks/useProfileContext";
import MobileMocket from "./MobileMocket";
import application from '../assets/images/application.png'
import file from '../assets/images/file.png'
import phone from '../assets/images/phone.png'
import envelope from '../assets/images/envelope.png'
import globe from '../assets/images/globe.png'
import map from '../assets/images/map-marker.png'
import { useEffect, useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useUserContext } from "../hooks/useUserContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { useOutletContext } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

const BusinessInfo = () => {
    const { profile, dispatch } = useProfileContext();
    const { user } = useUserContext();
    const [isLoading, setIsLoading] = useState(false)
    const [alert, setAlert] = useState("")
    const [message, setMessage] = useState('');
    const [handleTutorialClick] = useOutletContext();
    const [appSelected, setAppSelected] = useState({ 'src': application, 'namePlaceHolder': 'choose from above', 'linkPlaceHolder': 'choose from above' });
    const businessApps = [file, phone, globe, map, envelope]
    const [link, setLink] = useState();
    const [linkName, setLinkName] = useState();
    const [name, setName] = useState(profile.name);
    const [job, setJob] = useState(profile.profiles[profile.activeProfile].job);
    const [document, setDocument] = useState();
    const [bio, setBio] = useState(profile.profiles[profile.activeProfile].bio);
    const isValid = isPhoneValid(link);
    useEffect(() => {
        if (handleTutorialClick[0]) {
            console.log('Start Tutorial button clicked in Outlet component');
            const driverObj1 = driver({
                showProgress: true,  // Because everyone loves progress bars!
                steps: [
                    {
                        element: '.profileContainer',
                        popover: {
                            title: 'Your Profile',
                            description: "Here are your profile overview. You can delete any social media application or business information by clicking on the minus button."
                        }
                    },
                    {
                        element: '.bio-business',
                        popover: {
                            title: 'Profile Biography',
                            description: 'Here you can enter your biography that is shown inside your profile.'
                        }
                    },
                    {
                        element: '.profile-name',
                        popover: {
                            title: 'Profile Name',
                            description: 'Here you can change your profile name. (ps. This name is applied to all profiles)'
                        }
                    },
                    {
                        element: '.profile-job',
                        popover: {
                            title: 'Profile Job',
                            description: 'Here you can change your profile job title.'
                        }
                    },
                    {
                        element: '.business-section3',
                        popover: {
                            title: 'Business Info.',
                            description: 'Here you choose your business information by selescting one of the shown icons document, phone number, website, location, email.'
                        }
                    },
                    {
                        element: '.businessInfo-form',
                        popover: {
                            title: 'Business Info. Input',
                            description: "Here you should enter the needed data shown in the place holder such as the link and it's name shown in the profile."
                        }
                    }
                ]
            });
            driverObj1.drive();

            // Call your function or perform any other actions here
        }
     }, [profile,handleTutorialClick])

    const handleAppSave = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const maxAllowedSize = 5 * 1024 * 1024;
        let icon;
        switch (appSelected.src) {
            case map:
                icon = "map"
                break;
            case phone:
                icon = "phone"
                break;
            case globe:
                icon = "globe"
                break;
            case envelope:
                icon = "envelope"
                break;
            case file:
                icon = "file"
                break;
        }
        console.log(icon)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('Name', linkName);
        let contentType = "application/json"
        if (icon === "envelope") {
            let l = 'mailto:' + link
            formData.append('Link', l);
        }
        else if (icon !== "file"){
            if(icon === "globe"){
                let httpExist = link.split("//");
                let realLink1='https://';
                let realLink2 = 'www.';
                let y ;
                if(link.search("https://") === -1 && link.search("www.") !== -1){
                    realLink1 += link;
                }
                else if(link.search("https://") !== -1 && link.search("www.") === -1){
                    let httpExist = link.split("//");
                    realLink1+='www.'+httpExist[1];
                }
                else{
                    realLink1+=realLink2+link;
                }
                console.log(realLink1)
                formData.append('Link', realLink1);
            }
            else{
                formData.append('Link', link);
            }
        }
        else {
            console.log(document)
            const lastDotIndex = document.name.lastIndexOf('.');
            const fileExtension = document.name.substring(lastDotIndex + 1);
            console.log(fileExtension); // Outputs: "pdf"
            if (fileExtension == "pdf") {
                if (document.size > maxAllowedSize) {
                    setIsLoading(false)
                    setAlert("error")
                    setMessage("File Exceeded Limit 5MB")
                    return
                }
                else {
                    formData.append('Link', document);
                    contentType = "multipart/form-data"
                }
            } else {
                setMessage("Only PDFs are allowed.")
                setAlert("error")
                setIsLoading(false)
                return
            }
        }
        formData.append('ProfileIdx', profile.activeProfile);
        formData.append('Icon', icon);

        try {
            await axios.post(`/users/updateBi/${icon}`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": contentType,
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    console.log(json)
                    dispatch({ type: "UPDATE_PROFILES", payload: json })
                    setLink('')
                    setLinkName('')
                    setDocument('')
                    setIsLoading(false)
                })
        }
        catch (err) {
            console.log("No internet connection")
        }
    }

    const handleLinkClick = (app) => {
        let obj
        setLink("")
        setLinkName("")
        switch (app) {
            case map:
                obj = {
                    'src': app,
                    'namePlaceHolder': 'Enter location name',
                    'linkPlaceHolder': 'Enter location link',
                }
                break;
            case phone:
                obj = {
                    'src': app,
                    'namePlaceHolder': 'Enter number name',
                    'linkPlaceHolder': 'Enter number',
                }
                break;
            case globe:
                obj = {
                    'src': app,
                    'namePlaceHolder': 'Enter website name',
                    'linkPlaceHolder': 'Enter website link',
                }
                break;
            case envelope:
                obj = {
                    'src': app,
                    'namePlaceHolder': 'Enter email name',
                    'linkPlaceHolder': 'Enter email',
                }
                break;
            case file:
                obj = {
                    'src': app,
                    'namePlaceHolder': 'Enter document name',
                    'linkPlaceHolder': 'Choose document',
                }
                break;
        }
        setAppSelected(obj);
    }

    const handleSave = async () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('Name', name);
        formData.append('ProfileIdx', profile.activeProfile);
        formData.append('Job', job);
        formData.append('Bio', bio);

        try {
            await axios.post(`/users/businessPage`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    dispatch({ type: "UPDATE_PROFILES", payload: json })
                    setIsLoading(false)

                })
        }
        catch (err) {
            console.log("No internet connection")
        }
    }
    let c;
    if (profile.profiles[profile.activeProfile].colorTheme === '#d9d9d9') {
        c = '217, 217, 217'
    }
    else {
        c = profile.profiles[profile.activeProfile].colorTheme
    }
    return (
        <div className="socialMediaHolder b-holder">
            {isLoading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <div className="mobile-contactInfoHeader">
                <h2 style={{ fontFamily: 'poppins', fontSize: '20px' }}>
                    Business Info
                </h2>
            </div>
            <div className="profileView-leftPart">
                <MobileMocket profile={profile.profiles[profile.activeProfile]} />
            </div>
            <div className="profileView-rightPart">
                <div className="bio bio-business" style={{'background': `rgba(${c},.4)`}} >
                    <div className='bioTitle bioTitle-business'>Bio</div>
                    <textarea name="bio-text"
                        type="text"
                        rows={2}
                        cols={1000}
                        id="bio"
                        value={bio}
                        className="textarea"
                        onChange={(e) => { setBio(e.target.value) }}
                        style={{ 'background': `transparent`, "resize": 'none' }}
                    />
                    <div className="buttonsHolder-sm buttonsHolder-business">
                        <button onClick={handleSave} style={{ 'background': `white`,'padding':'13px 50px' }}> SAVE</button>
                    </div>
                </div>
                <div className="nameJobHolder">
                {!profile.manager && <p className="bt2">Name and Job Title</p>}
                {!profile.manager && <div style={{ 'background': `rgba(${c},.4)` }} className="business-section1">
                    <input value={name} onChange={e => setName(e.target.value)} placeholder="Name" className="profile-name" type="text" />
                    <input value={job} onChange={e => setJob(e.target.value)} placeholder="Job" className="profile-job" type="text" />
                    <div className="buttonsHolder-sm buttonsHolder-business">
                        <button onClick={handleSave} style={{ 'background': `white`,'padding':'13px 50px' }}> SAVE</button>
                    </div>
                </div>}
                </div>
                <p style={{'marginBottom':'0px'}} className="bTitles">Business Information</p>
                <div className="smInputContainer InputContainer-business">
                    <div className="business-section3">
                        {
                            businessApps.map((app, idx) => (
                                <img onClick={e => handleLinkClick(app)} className="businessInfo-img" src={app} key={idx} />
                            ))
                        }
                    </div>
                    <form id="appSubmit2" className="businessInfo-form" onSubmit={(e) => handleAppSave(e)}>

                        {message && <Alert severity={alert}>{message}</Alert>}
                        <div className="smInputHolder smInputHolder-business" >
                            <img src={appSelected.src} />
                            <div className="vertical-space" />
                            <input required value={linkName || ""} placeholder={appSelected.namePlaceHolder} type="text" onChange={e => setLinkName(e.target.value)} />
                        </div>
                        {appSelected.src == phone && <PhoneInput
                            className="phoneInput"
                            defaultCountry="eg"
                            value={link}
                            onChange={(phone) => setLink(phone)}
                            forceDialCode={true}

                        />}
                        {appSelected.src === file &&
                            <input required className="fileInput" id='input-file' type='file' accept=".pdf" name="Link" onChange={(e) => setDocument(e.target.files[0])} />
                        }
                        {(appSelected.src !== phone && appSelected.src !== file) && <div className="smInputHolder smInputHolder-business" >
                            <img src={appSelected.src} />
                            <div className="vertical-space" />
                            <input required value={link || ""} placeholder={appSelected.linkPlaceHolder} type="text" onChange={e => setLink(e.target.value)} />
                        </div>}
                    </form>
                    {(appSelected.src === phone) ? <button className="buttonSM buttonSM-BI" disabled={!isValid} form="appSubmit2" type="submit"> Add</button> : <button className="buttonSM buttonSM-BI" disabled={appSelected.namePlaceHolder == "choose from above"} form="appSubmit2" type="submit"> Add</button>}
                </div>
            </div>
        </div>
    );
}

export default BusinessInfo