import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProfileContext } from '../../hooks/useProfileContext';

const EmployeeCard = ({ employee, idx }) => {
    const location = useLocation()
    const { profile, dispatch } = useProfileContext()
    const [anchorEl, setAnchorEl] = useState(null);
    const { companyName } = useParams();
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const tapEntries = Object.entries(employee.numberOfTapsDate);
    const tapLastkey = tapEntries[tapEntries.length - 1];
    const tapLastvalue = tapLastkey[1];
    const contactEntries = Object.entries(employee.noOfContactDownloads);
    const contactlastkey = contactEntries[contactEntries.length - 1];
    const contactlastvalue = contactlastkey[1];
    //console.log(lastvalue);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseNavigate = () => {
        dispatch({ type: 'SET_PROFILES', payload: employee })
        navigate("/UserDashboard/" + companyName)
        setAnchorEl(null);
    };


    return (
        <div className="cardContainer">
            <div className='card-upper'>
                <Avatar sx={{ width: 90, height: 90 }} alt="Remy Sharp" src={employee.profiles[employee.activeProfile].image1} />
                {location.pathname.search('edit') !== - 1 && <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='dots'
                >
                    <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: "#000000", }} />
                </Button>}
                {location.pathname.search('edit') !== - 1 && <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={handleCloseNavigate}>Edit</MenuItem>
                </Menu>}
            </div>
            <div className='card-middle'>
                <span className='card-name'>{employee.name}</span>
                {(idx !== 0) && <span className='card-second'> {employee.profiles[employee.activeProfile].job} </span>}
            </div>
            {(idx !== 0) ? <div className='card-lower'>
                <div className='taps'>
                    <span className='tap'>{contactlastvalue}</span>
                    <span className='card-second'>Contact</span>
                </div>
                <div className='taps'>
                    <span className='con'> {tapLastvalue} </span>
                    <span className='card-second'>taps</span>
                </div>
            </div> :
                <div style={{'opacity':'0.5','fontSize':'14px'}}>
                    Here is your corporate profile, any edit in this profile will immediatley change in all profiles.
                </div>
            }
        </div>
    )
}

export default EmployeeCard