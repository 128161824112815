import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import file from '../assets/images/file.png'
import phone from '../assets/images/phone.png'
import envelope from '../assets/images/envelope.png'
import globe from '../assets/images/globe.png'
import map from '../assets/images/map-marker.png'
import { useProfileContext } from '../hooks/useProfileContext';
import { useUserContext } from '../hooks/useUserContext';
import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
const BusinessLinks = ({ corp, props, color, idx }) => {
    const { profile, dispatch } = useProfileContext();
    const { user } = useUserContext()
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => { }, [dispatch])

    let icon;
    switch (props.Icon) {
        case "map":
            icon = map
            break;
        case "phone":
            icon = phone
            break;
        case "globe":
            icon = globe
            break;
        case "envelope":
            icon = envelope
            break;
        case "file":
            icon = file
            break;
    }

    const handleAppRemove = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', profile.activeProfile);
        formData.append('LinkIdx', idx);
        formData.append('Link', props.Link);

        try{
            await axios.post( `/users/deleteBi`, formData, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then(async (res) => {
                    const json = res.data
                    console.log(json)
                    dispatch({ type: "UPDATE_PROFILES", payload: json })
                setIsLoading(false)
                })
        }
        catch(err){
            console.log(err)
            console.log("No internet connection")
        }
    }


    return (
        <div style={{'width':'100%'}}>
            {!isLoading ? <a target="_blank" style={{ 'background': `rgba(${color},.4)` }} className='businessLink' href={(icon === phone) ? "tel:" + props.Link : props.Link}>
                {corp && <div className='businessRemoveContainer'>
                    <FontAwesomeIcon onClick={e => handleAppRemove(e)} className='removeIcon' icon={faCircleMinus} />
                </div>}
                <img style={{ 'width': '32px', 'height': '32px', "padding": '5px' }} src={icon} />
                <span className='business-links-mobile'>{props.Name}</span>
            </a> : <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
        </div>
    );
}

export default BusinessLinks