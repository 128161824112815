import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import { Outlet, useLocation } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import MobileNavbar from '../components/MobileNavbar';
import ReactGA from "react-ga";
import Hotjar from '@hotjar/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
const TRACKING_ID = "G-PN18N7QM46"
ReactGA.initialize(TRACKING_ID)

const siteId = 3595228;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);
export default function RootLayout() {
    const [display,setDisplay] = useState('block')
    return (
        <div className="background">
            {/* <div style={{'display':display}} className='sale-bar'>
                <span onClick={e => setDisplay('none')} style={{'width':'98%','textAlign':'right','display':'block','cursor':'pointer'}}> <FontAwesomeIcon icon={faXmark} /> </span>
                <p style={{'margin':'0'}}> <strong style={{'fontFamily':'poppins-bold'}}>Save big in the End Of The Year SALE.</strong><br/> Fill your cart with cardtap business card. Discover our bundles offers, starting from 399 EGP + Free shipping. </p>
            </div> */}
            <div className="container">
                <Navbar />
                <MobileNavbar />
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}