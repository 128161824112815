import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { useProfileContext } from '../hooks/useProfileContext';
import { useUserContext } from '../hooks/useUserContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
const Platform = ({ corp, props, color, idx }) => {
    const { profile, dispatch } = useProfileContext();
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useUserContext();
    const { id } = useParams();
    const { Name } =useParams()
    const handleAppRemove = async () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('Email', profile.email);
        formData.append('ProfileIdx', profile.activeProfile);
        formData.append('AppIndex', idx);
        formData.append('PlatformLink',props.PlatformLink)
        console.log(corp)
        await axios.post(`/users/deleteSm`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                'Authorization': `Bearer ${user.token}`
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
                dispatch({ type: "UPDATE_PROFILES", payload: json })
                setIsLoading(false)
            })
    }

    const handleAppAnalytics = async () => {
        const formData = new FormData();
        formData.append('Name', Name);
        formData.append('id', id);
        formData.append('AppIndex', idx);

        await axios.post(`/analytics/appTap`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
            })
            .catch((err) => {
                console.log("No internet Connection")
            })
    }

    return (
        <div>
            {!isLoading ? <a style={{ 'background': `rgba(${color},.4)` }} target="_blank" onClick={!corp ? handleAppAnalytics : undefined} className='platform' href={props.PlatformLink}>
                {corp && <div className='removeContainer'>
                    <FontAwesomeIcon onClick={(e) => { e.preventDefault(); handleAppRemove(); }} className='removeIcon' icon={faCircleMinus} />
                </div>}
                <img style={{ 'width': '50px', 'height': '50px', "padding": '5px' }} src={props.PlatformIcon} />
            </a> : <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
        </div>
    );
}

export default Platform